import EventInvitationTemplate1 from './Template1'
import EventInvitationTemplate2 from './Template2'
import EventInvitationTemplate3 from './Template3'
import EventInvitationTemplate4 from './Template4'
import EventInvitationTemplate5 from './Template5'
import { INVITATION_TEMPLATE_IDS } from 'constants/invitationTemplate'
import { useInvitationTemplate } from '@/store/zustand/InvitationTemplateManagement'
function EventInvitationPreview() {
  const selectedTemplate = useInvitationTemplate(
    state => state.selectedTemplate
  )
  switch (selectedTemplate) {
    case INVITATION_TEMPLATE_IDS.template1:
      return <EventInvitationTemplate1 />
    case INVITATION_TEMPLATE_IDS.template2:
      return <EventInvitationTemplate2 />
    case INVITATION_TEMPLATE_IDS.template3:
      return <EventInvitationTemplate3 />
    case INVITATION_TEMPLATE_IDS.template4:
      return <EventInvitationTemplate4 />
    case INVITATION_TEMPLATE_IDS.template5:
      return <EventInvitationTemplate5 />
    default:
      return <EventInvitationTemplate1 />
  }
}

export default EventInvitationPreview
