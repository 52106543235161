import Footer from '@/components/organisms/Footer/Footer'
import Header from '@/components/organisms/Header/Header'
import { STATUS, checkCapacity } from '@/utils/helpers'
import Loading from 'components/atoms/Loading'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'utils/adapters'
import { getAttendeeBookingDetail, useAttendeeEventDetail } from './mixins'
import { FC } from 'react'
import EventDetailContainer from 'components/organisms/EventDetail'
import EventDetail from 'components/organisms/EventDetail/components/EventDetail'
import { Button } from '@/components/ui/button'
import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'

const AttendeeEventDetail: FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { t } = useTranslation()

  const { checkSubTitle } = checkCapacity()

  const { data, loading } = useAttendeeEventDetail(id)
  const { data: attendeeBookingData, loading: attendeeBookingLoading } =
    getAttendeeBookingDetail(id)

  const event = data?.event

  if (loading || attendeeBookingLoading) {
    return <Loading />
  }

  return (
    <div className='w-full h-full'>
      <Header
        title={event.title as string}
        subTitle={checkSubTitle(
          event.isExpired,
          event.capacity,
          event.attendeesCount,
          event.eventSetting?.isLockedRegister,
          event.isAlwaysActive
        )}
        showLanguageSelector
        bgColor={event?.eventSetting?.primaryColor}
        displayBackIcon
        handleBack={() => history.goBack()}
      />
      <EventDetailContainer event={event}>
        <div className='relative px-3 md:py-4 md:px-32 flex py-3'>
          <div>
            <EventDetail event={event}>
              {!event?.isExpired && (
                <Button
                  disabled={
                    event?.attendeeStatus !== STATUS.registered ||
                    Boolean(event.price)
                  }
                  // TODO: un-comment the below code
                  // disabled={event?.attendeeStatus !== STATUS.registered}
                  type='submit'
                  onClick={() =>
                    history.push(`/events/${event._id}/edit-booking`)
                  }
                  style={{
                    backgroundColor: event?.eventSetting?.primaryColor,
                    color: setTextColorBasedOnBackground(
                      event?.eventSetting?.primaryColor
                    ),
                  }}
                  className='w-full max-w-md'
                  size='lg'
                >
                  {t('Edit Booking')}
                </Button>
              )}
            </EventDetail>
          </div>
        </div>
      </EventDetailContainer>
      <Footer bgColor={event?.eventSetting?.primaryColor} />
    </div>
  )
}

export default AttendeeEventDetail
