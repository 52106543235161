import './style.scss'

import { Dialog, DialogContent, DialogFooter } from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Letter5, Template4Logo, Template5Bg, Template5Logo } from '@/assets/images'
import { useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'utils/adapters'

import { Button } from 'components/ui/button'
import { INVITATION_STATUS } from '@/constants/invitationTemplate'
import { Icons } from '@/components/ui/icon'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { cn } from '@/lib/utils'
import { useForm } from 'react-hook-form'
import { useInvitationTemplate } from '@/store/zustand/InvitationTemplateManagement'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

function EventInvitationTemplate5() {
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(false)
  const [isFlip, setIsFlip] = useState(false)
  const [displayActionAnimation, setDisplayActionAnimation] = useState(false)
  const templateDetailPreview = useInvitationTemplate(
    state => state.templateDetailPreview
  )

  const [openRsvpForm, setOpenRsvpForm] = useState(false)
  const { eventId } = useParams<{ eventId: string }>()

  const sectionRefs = useRef<any>([]) // Create a reference array to store all sections

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const target = entry.target

          if (entry.isIntersecting) {
            target.classList.add('observed-animation-fade-in-active')
          } else {
            target.classList.remove('observed-animation-fade-in-active')
          }
        })
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    )

    sectionRefs.current.forEach(section => {
      if (section) observer.observe(section) // Observe each section
    })

    return () => {
      // Cleanup observer on component unmount
      if (sectionRefs.current) {
        sectionRefs.current.forEach(section => {
          if (section) observer.unobserve(section)
        })
      }
    }
  }, [])

  useEffect(() => {
    if (templateDetailPreview === null) {
      history.goBack()
    }
  }, [templateDetailPreview])

  const handleReplay = () => {
    setDisplayActionAnimation(false)
    setIsOpen(false)
    setIsFlip(false)
    setTimeout(() => setIsOpen(true), 1000)
  }

  useEffect(() => {
    setTimeout(() => setIsOpen(true), 500)
  }, [])

  useEffect(() => {
    setTimeout(() => setDisplayActionAnimation(true), 3000)
  }, [isOpen])

  const handleUpdateInvitation = async (status: number) => {
    if (status === INVITATION_STATUS.ATTEND)
      window.location.href = `${
        import.meta.env.VITE_REGISTER_EVENT
      }/events/${eventId}`
  }

  const formSchema = z.object({
    message: z.string().max(500, {
      message:
        'Please ensure that your message does not exceed 500 characters.',
    }),
    email: z.string().max(250, {
      message:
        'Please ensure that your message does not exceed 200 characters.',
    }),
    firstName: z.string().max(250, {
      message:
        'Please ensure that your message does not exceed 200 characters.',
    }),
    lastName: z.string().max(250, {
      message:
        'Please ensure that your message does not exceed 200 characters.',
    }),
  })
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      message: '',
    },
  })

  const onSubmit = async (values: { message: string }) => {
    form.reset({ message: '' })
    setOpenRsvpForm(false)
  }

  const topContent =
    templateDetailPreview?.cardTopContent ||
    'You are invited \n annual drinks \n party \n 2024'
  const bottomContent =
    templateDetailPreview?.cardBottomContent ||
    'Wednesday \n 18th \n September \n 7PM'
  const logo = templateDetailPreview?.logo || Template5Logo
  return (
    <div className='template-5 font-MOPGGothic'>
      <div
        style={{
          backgroundImage: `url(${Template5Bg})`,
        }}
        className='bg-center bg-cover h-[400px] md:h-[800px] overflow-hidden relative'
      >
        <div className='mt-[20px]'>
          <div className={isOpen ? 'envelope open' : 'envelope close'}>
            <div
              className={cn(
                'relative flip-card card h-full flex justify-center items-center w-[100%] -rotate-90'
              )}
            >
              <div
                className={cn(
                  'flip-card-inner w-[90%] h-full relative text-center',
                  isFlip && 'flip-card-rotate'
                )}
              >
                <div className='flip-card-front relative bg-white shadow-lg'>
                  <div className='shadow-lg p-4 md:p-6 lg:p-6 w-full h-full'>
                    <div className='mt-[30%] lg:mt-[20%] relative h-[110px] md:h-[200px] lg:h-[300px] max-w-full letter'>
                      <img
                        src={Letter5}
                        className='w-full h-full object-cover'
                      />
                      <div
                        className='md:tracking-[0.5rem] absolute bottom-[-1%] text-sm md:text-2xl 
                        left-1/2 -translate-x-1/2 uppercase text-white font-bold w-full lg:text-[2.25rem] 
                        leading-[1rem] lg:leading-[1.75rem]'
                      >
                        Marc O&apos;polo
                      </div>
                    </div>
                    <div className='flex flex-col gap-4 md:gap-10 mt-4 md:mt-16'>
                      <div
                        className='text-center whitespace-pre-line text-black text-xs md:text-lg 
                        lg:text-xl uppercase font-bold !leading-4 md:!leading-8'
                      >
                        {topContent}
                      </div>
                      <div
                        className='text-center whitespace-pre-line text-black text-xs md:text-lg 
                        lg:text-xl uppercase font-bold !leading-4 md:!leading-8'
                      >
                        {bottomContent}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flip-card-back'></div>
              </div>
            </div>
            <div className='flap-outside flap-container'>
              <div className='flap flap-top'>
                <div className='lining' />
              </div>
            </div>
            <div className='flap-inside flap-container'>
              <div className='flap flap-bottom' />
            </div>
          </div>
          {displayActionAnimation && (
            <div className='reset flex gap-2 flex-col absolute top-[20%] md:top-[40%] left-[10%] md:left-[20%] transform -translate-x-1/2 -translate-y-1/2'>
              <button
                id='open'
                onClick={() => setIsFlip(!isFlip)}
                className='bg-[#EFEFEF] p-2 rounded-full'
              >
                <Icons.flip />
              </button>
              <button
                id='reset'
                onClick={handleReplay}
                className='bg-[#EFEFEF] p-2 rounded-full'
              >
                <Icons.replay />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className='py-10 md:py-20 px-10 md:px-20 lg:px-40 text-black bg-[#EFEFEF]'>
        <h1 className='font-plantin text-2xl md:text-5xl text-center uppercase observed-animation-fade-in-active'>
          {templateDetailPreview?.title}
        </h1>
        <div className='flex flex-col gap-8 md:flex-row pt-10 md:pt-20 lg:pt-40 justify-between text-center font-grotesque text-[#333333 '>
          <div
            className='tracking-[2.5px] leading-tight'
            ref={el => (sectionRefs.current[0] = el)}
          >
            <div className='mb-4 uppercase font-bold'>VERANSTALTET VON</div>
            <div className='text-xl'>{templateDetailPreview?.host}</div>
          </div>
          <div
            className='tracking-[2.5px] leading-tight'
            ref={el => (sectionRefs.current[1] = el)}
          >
            <div className='mb-4 uppercase font-bold whitespace-pre-line'>
              DATUM
            </div>
            <div className='text-xl whitespace-pre-line'>
              {templateDetailPreview?.eventTime}
            </div>
          </div>
        </div>
      </div>

      <div
        ref={el => (sectionRefs.current[2] = el)}
        className='py-10 md:py-20 px-10 md:px-20 lg:px-40 bg-white text-center font-grotesque text-black'
      >
        <div className='uppercase text-large font-bold'>
          BITTE HIER REGISTRIEREN
        </div>
        <div className='flex flex-col items-center md:flex-row md:gap-40 justify-center'>
          <Button
            variant='outline'
            className='w-[250px] md:w-[300px] mt-10 bg-black hover:bg-[#EFEFEF] transition-transform py-[16px] text-white font-normal rounded-none uppercase border-2 border-black'
            onClick={() => handleUpdateInvitation(INVITATION_STATUS.ATTEND)}
          >
            ICH NEHME TEIL
          </Button>
          <Button
            variant='outline'
            className='w-[250px] md:w-[300px] mt-10 border-2 border-black py-[16px] font-normal bg-white hover:bg-[#EFEFEF] transition-transform rounded-none text-black hover:text-[#2f3235] hover:border-white uppercase'
            onClick={() => setOpenRsvpForm(true)}
          >
            ICH NEHME NICHT TEIL
          </Button>
        </div>
      </div>
      <div className='py-10 md:py-20 px-10 md:px-20 lg:px-40 flex justify-center flex-col items-center text-black bg-[#EFEFEF]'>
        <img src={logo} className='w-[200px]' />

        <div className='flex justify-center mt-4 items-center gap-4 text-sm'>
          <a href={import.meta.env.VITE_IMPRINT_URL}>Imprint</a>
          <a href={import.meta.env.VITE_PRIVACY_URL}>Privacy</a>
          {/* <div>{copyRight}</div> */}
        </div>
      </div>
      {openRsvpForm && (
        <Dialog open={openRsvpForm} onOpenChange={() => setOpenRsvpForm(false)}>
          <DialogContent className='sm:max-w-[425px]'>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className='space-y-4'
              >
                <FormField
                  control={form.control}
                  name='email'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>E-Mail</FormLabel>
                      <FormControl>
                        <Input placeholder='' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='firstName'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Vorname</FormLabel>
                      <FormControl>
                        <Input placeholder='' {...field} />
                      </FormControl>
                      {/*<FormMessage />*/}
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='lastName'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Nach-/Familienname</FormLabel>
                      <FormControl>
                        <Input placeholder='' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='message'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Ihre private Nachricht an den Host (freiwillig)
                      </FormLabel>
                      <FormControl>
                        <Textarea {...field} id='message' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <DialogFooter>
                  <Button
                    className='w-full bg-[#2f3235] text-[#fff] uppercase'
                    type='submit'
                  >
                    Bestätigen
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}
export default EventInvitationTemplate5
