import {
  CUSTOM_FIELD_TYPE,
  FIELD_TYPE,
  customFieldType,
} from '@/constants/customFieldType'
import { FC, useState } from 'react'
import { Field, FieldArray } from '@/utils/adapters'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { PenOff, Pencil, Plus, Trash2 } from 'lucide-react'
import { filter, flatMap, groupBy } from 'lodash'

import Checkbox from '@/components/atoms/Checkbox'
import { Input } from '@/components/ui/input'
import { SelectAdapter } from '@/components/ui/select-adapter'
import { Switch } from '@/components/ui/switch'
import { cn } from '@/lib/utils'
import { t } from 'i18next'
import { trimWhiteSpace } from '@/utils/helpers'
import { useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'

interface FormBuilderInputItemProps {
  name: string
  index: number
  columnId: string
}

const required = (value: any) =>
  value ? undefined : t('This field is required!')

const isDisplayOptions = (type: string) => {
  return (
    type === CUSTOM_FIELD_TYPE.CHECK_BOX ||
    type === CUSTOM_FIELD_TYPE.SELECT_BOX ||
    type === CUSTOM_FIELD_TYPE.RADIO_BUTTON
  )
}

export const FormBuilderInputItem: FC<FormBuilderInputItemProps> = ({
  name,
  index,
  columnId,
}) => {
  const { t } = useTranslation()

  const form = useForm()

  const currentField = form.getState().values?.allQuestions[columnId][index]

  const isDefaultField = currentField?.fieldType === FIELD_TYPE.DEFAULT_FIELD

  const [isEditing, setIsEditing] = useState(!isDefaultField)

  const isEmailField = currentField?.id === 'email'
  const type = currentField?.type

  const validateTitle = (value, allQuestions, columnId, index) => {
    if (!value) {
      return t('custom_selections.error.required')
    }

    const labels: string[] = []

    allQuestions.forEach((columns, columnIndex: number) => {
      columns.forEach((item: any, idx: number) => {
        if (columnIndex === columnId && idx === index) {
          return
        }
        if (item.label) {
          labels.push(item.label)
        }
      })
    })

    const isDuplicate = labels.includes(value)

    return isDuplicate ? t('custom_selections.error.title_unique') : undefined
  }

  const renderDefaultFieldHelpText = (field: any) => {
    switch (field.id) {
      case 'email':
        return t('Email')
      case 'firstName':
        return t('First Name')
      case 'lastName':
        return t('Last Name')
      case 'salutation':
        return t('Salutation')
      case 'professional':
        return t('Academic Title')
      case 'jobTitle':
        return t('Job Title')
      case 'company':
        return t('Company')
      case 'address.street':
        return t('Street')
      case 'address.city':
        return t('City')
      case 'address.zipCode':
        return t('Zip')
      case 'address.country':
        return t('Country')
      case 'workPhone':
        return t('Work Phone')
      case 'mobilePhone':
        return t('Mobile Phone')
      case 'fax':
        return t('Fax')
      default:
        return ''
    }
  }

  return (
    <div className=''>
      <div className='flex gap-6 flex-col md:flex-row relative'>
        <div className='z-999'>
          <Field
            name={`${name}.type`}
            validate={required}
            placeholder={t('Select box')}
          >
            {({ input, meta }: any) => (
              <FormItem>
                <div className='w-[200px] md-w-[170px]'>
                  <SelectAdapter
                    input={input}
                    meta={meta}
                    options={customFieldType}
                    title={t('Type')}
                    isRequired
                    disabled={isDefaultField}
                  />
                </div>
              </FormItem>
            )}
          </Field>
        </div>
        <div className='w-[80%] min-w-[200px] flex items-center justify-between gap-2'>
          <div className='w-[90%]'>
            <Field
              name={`${name}.label`}
              type='text'
              validate={(value, allValues: any) =>
                validateTitle(value, allValues.allQuestions, columnId, index)
              }
              format={trimWhiteSpace}
              formatOnBlur
            >
              {({ input, meta }: any) => {
                return (
                  <FormItem className='relative'>
                    <FormLabel meta={meta} isRequired>
                      {isDefaultField
                        ? t('event_form_builder.field_title', {
                            field: renderDefaultFieldHelpText(currentField),
                          })
                        : t('Title')}
                    </FormLabel>
                    <Input
                      {...input}
                      meta={meta}
                      maxLength='256'
                      className='bg-white'
                      disabled={!isEditing}
                    />
                  </FormItem>
                )
              }}
            </Field>
          </div>
          {isDefaultField && (
            <div className='w-[10%] mt-4'>
              {isEditing ? (
                <Pencil
                  onClick={() => setIsEditing(!isEditing)}
                  className='w-[20px] text-gray-600 cursor-pointer'
                />
              ) : (
                <PenOff
                  onClick={() => setIsEditing(!isEditing)}
                  className='w-[20px] text-gray-600 cursor-pointer'
                />
              )}
            </div>
          )}
        </div>
      </div>
      <div className='flex gap-10 mt-6 items-center'>
        <Field name={`${name}.required`} type='checkbox' defaultValue={false}>
          {({ input, meta }: any) => (
            <div className='flex gap-2'>
              <Checkbox {...input} disabled={isEmailField} />
              <div className=''>{t('Is required?')}</div>
            </div>
          )}
        </Field>
        <Field name={`${name}.enabled`} formatOnBlur className='h-full'>
          {({ input, meta }: any) => {
            return (
              <FormItem className='flex flex-row gap-4'>
                <FormLabel meta={meta} className='text-base'>
                  {t('custom_selections.enable_label')}
                </FormLabel>
                <div className=''>
                  <Switch
                    disabled={isEmailField}
                    id={name}
                    checked={input.value}
                    onCheckedChange={value => input.onChange(value)}
                  />
                </div>
              </FormItem>
            )
          }}
        </Field>
      </div>
      {!isDefaultField && isDisplayOptions(type) && (
        <Options index={index} columnId={columnId} />
      )}
    </div>
  )
}

type OptionsProps = {
  index: number
  columnId: string
}

const Options = ({ index, columnId }: OptionsProps) => {
  const { t } = useTranslation()

  const validateOption = (value, allValues, index) => {
    if (!value) {
      return t('custom_selections.error.required')
    }

    if (allValues?.[index]?.options) {
      const groupedByValue = groupBy(allValues?.[index].options, 'value')

      const duplicates = flatMap(
        filter(groupedByValue, group => group.length > 1)
      ) as any

      if (duplicates.length && value === duplicates[0]?.value) {
        return t('custom_selections.error.duplicate')
      }
    }

    return undefined
  }

  const validateOptionsArray = options => {
    return !options || options.length === 0
      ? t('custom_selections.error.at_least_one_option')
      : undefined
  }

  return (
    <FieldArray
      name={`allQuestions[${columnId}][${index}].options`}
      validate={validateOptionsArray}
    >
      {({ fields, meta: { error } }) => {
        return (
          <div>
            <fieldset className='rounded-2 border p-4 mt-6'>
              <legend>{t('Options')}</legend>
              <div>
                {fields?.map((name, optionIndex) => {
                  return (
                    <div key={name} className='flex justify-center mb-8'>
                      <div className='flex flex-col md:flex-row gap-4 md:gap-10 w-[100%] items-center'>
                        <div className='w-[30%] min-w-[200px]'>
                          <Field
                            name={`${name}.value`}
                            type='text'
                            format={trimWhiteSpace}
                            formatOnBlur
                            validate={(value, allValues: any) =>
                              validateOption(
                                value,
                                allValues?.allQuestions,
                                index
                              )
                            }
                          >
                            {({ input, meta }: any) => (
                              <FormItem>
                                <FormLabel
                                  meta={meta}
                                  isRequired
                                  className='font-bold'
                                >
                                  {t('custom_selections.option', {
                                    number: optionIndex + 1,
                                  })}
                                </FormLabel>
                                <Input
                                  {...input}
                                  meta={meta}
                                  maxLength='256'
                                  className='bg-white'
                                />
                              </FormItem>
                            )}
                          </Field>
                        </div>
                      </div>

                      <Trash2
                        onClick={() => {
                          return (
                            (fields.length ?? 0) > 1 &&
                            fields.remove(optionIndex)
                          )
                        }}
                        className={cn(
                          'w-[20px] text-gray-600 cursor-pointer',
                          (fields.length ?? 0) <= 1
                            ? 'pointer-events-none opacity-50'
                            : ''
                        )}
                      />
                    </div>
                  )
                })}

                <button
                  className='flex items-center space-x-2 cursor-pointer mt-6 text-primary font-bold'
                  onClick={() => fields.push({})}
                  type='button'
                >
                  <Plus className='w-[20px] mr-1' type='button' />{' '}
                  {t('Add option')}
                </button>
                {fields.length === 0 && (
                  <div className='text-red-500 text-sm mt-2'>
                    {t('custom_selections.error.at_least_one_option')}
                  </div>
                )}
              </div>
            </fieldset>
          </div>
        )
      }}
    </FieldArray>
  )
}
