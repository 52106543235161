import React, { FC, lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams, useQuery } from 'utils/adapters'
import { useAppDispatch, useAppSelector, useModal } from 'utils/hooks'

import Header from 'components/organisms/Header'
import AttendeeForm from './components/AttendeeInfo'
import AttendeeCustomField from './components/CustomField'
import DetailCost from './components/DetailCost'
import GoBackModal from './components/AttendeeInfo/GoBackModal'
import { setStep, setTotalPrice } from '@/store/Events'
import { Step } from '@/utils/helpers'
import { GET_EVENT_TERMS } from '@/graphql/queries/event'
import { setEventTerms } from '@/store/Events/EventsSlice'

const Stripe = React.lazy(() => import('../Payments/Stripe'))
const Footer = lazy(() => import('components/organisms/Footer'))

interface ParamsType {
  id: string
}

const RegisterEvent: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { id } = useParams<ParamsType>()

  const { isShow, showModal, hideModal } = useModal()

  const { step, totalPrice, isFullyBooked } = useAppSelector(
    state => state.eventsReducer
  )
  const event = JSON.parse(sessionStorage.getItem('currentEvent') as string)

  const attendeeInfo = JSON.parse(
    sessionStorage.getItem('attendee_info') as string
  )

  const language = (localStorage.getItem('i18nextLng') as string) || 'en'

  const checkTotalPrice = (totalPrice: number) => {
    if (totalPrice && totalPrice >= event.price) {
      return totalPrice
    } else {
      return event.price
    }
  }

  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret'
  )

  const checkRedirectEventPage = (currentEvent: any) => {
    if (
      !currentEvent ||
      localStorage.length === 0 ||
      sessionStorage.length === 0
    ) {
      history.push(`/events/${id}`)
      location.reload()
      return
    }
  }

  const { data: terms, loading: loadingTerms } = useQuery(GET_EVENT_TERMS, {
    variables: {
      id,
    },
    onCompleted: data => {
      dispatch(setEventTerms(data?.getEventTerms?.terms))
    },
    onError: ({ message }) => {
      console.log(message)
    },
  })

  const renderComponent = (step: number) => {
    const currentEvent = sessionStorage.getItem('currentEvent')
    checkRedirectEventPage(currentEvent)
    switch (step) {
      case Step.AttendeeForm:
        return <AttendeeForm />
      case Step.AttendeeCustomField:
        return <AttendeeCustomField />
      case Step.DetailCost:
        return <DetailCost />
      case Step.Payment:
        return (
          <>
            <Stripe
              mode='payment'
              action='register'
              value={checkTotalPrice(totalPrice)}
              eventId={event._id}
              attendee={attendeeInfo}
              currency={event.currency}
              eventSetting={event?.eventSetting}
            />
          </>
        )

      default:
        return null
    }
  }

  const handleGoBack = (step: number) => {
    switch (step) {
      case Step.AttendeeForm:
        showModal()
        break
      case Step.AttendeeCustomField:
        dispatch(setStep(Step.AttendeeForm))
        break
      case Step.DetailCost:
        dispatch(setTotalPrice(0))
        if (event?.customFields.length === 0) {
          dispatch(setStep(Step.AttendeeForm))
        } else {
          dispatch(setStep(Step.AttendeeCustomField))
        }
        break
      case Step.Payment:
        dispatch(setStep(Step.DetailCost))
        break

      default:
        break
    }
  }

  const onConfirm = () => {
    history.push(`/events/${id}`)
    localStorage.removeItem('inputDate')
    localStorage.removeItem('inputField')
    localStorage.removeItem('selectedFields')
    localStorage.removeItem('selectedRadio')
    localStorage.removeItem('isChecked')
    sessionStorage.clear()
  }

  if (clientSecret) {
    return <Stripe mode='result' action='register' />
  }

  return (
    <>
      <div
        className='w-full h-full'
        style={{
          fontFamily:
            event?.eventSetting?.fontFamily === 'Graphik'
              ? 'Graphik'
              : undefined,
        }}
      >
        <Header
          bgColor={event?.eventSetting?.primaryColor}
          title={event?.title}
          subTitle={isFullyBooked ? t('Fully booked') : undefined}
          showLanguageSelector={true}
          defaultLanguage={language}
          displayBackIcon
          handleBack={() => handleGoBack(step)}
        />

        <div className='min-h-[calc(100vh-54px-47px)] md:min-h-[calc(100vh-80px-47px)]'>
          {renderComponent(step)}
        </div>

        <Footer bgColor={event?.eventSetting?.primaryColor} />
      </div>
      <GoBackModal isShow={isShow} onClose={hideModal} onConfirm={onConfirm} />
    </>
  )
}

export default RegisterEvent
