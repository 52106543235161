import { CUSTOM_FIELD_TYPE, FIELD_TYPE } from '@/constants/customFieldType'
import { ChevronLeft, PenOff, Pencil, Plus, Trash2 } from 'lucide-react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { FieldArray, Form, arrayMutators, useParams } from 'utils/adapters'
import { setFormData, setStep } from 'store/Events'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'

import { Button } from '@/components/ui/button'
import { CustomFieldType } from '@/models'
import { EventType } from '@/constants/events'
import { FormBuilderInputItem } from './FormBuilderInputItem'
import { PATH_NAME } from '@/routes/routesMap'
import { SaveChangesNowButton } from '@/components/molecules/SaveChangesNow'
import { Separator } from '@/components/ui/separator'
import { cn } from '@/lib/utils'
import { useRouteMatch } from 'react-router'
import { useTranslation } from 'react-i18next'

type ParamsType = {
  id: string
}

export const FormBuilder = () => {
  const { t } = useTranslation()
  const { id } = useParams<ParamsType>()
  const dispatch = useAppDispatch()
  const matchEditRoute = useRouteMatch(PATH_NAME.EDIT_EVENT)

  const { step, formData } = useAppSelector(state => state.eventsReducer)

  const formatFormValues = (values: any) => {
    // format options
    const formattedValue = values?.customSelections?.map(question => ({
      ...question,
      systemField: undefined,
      options: question?.options?.map(option => ({
        ...option,
        label: option?.value,
      })),
    }))

    const customSelections: any[] = []

    const formattedQuestions = values?.allQuestions?.map(columnsQuestions =>
      columnsQuestions.map(question => {
        const { systemField, ...rest } = question
        if (question.fieldType === FIELD_TYPE.CUSTOM_SELECTION) {
          customSelections.push(rest)
        }
        return question.fieldType === FIELD_TYPE.DEFAULT_FIELD
          ? { id, ...rest }
          : { ...rest, systemField: undefined }
      })
    )

    delete values?.toggleDefaultFields?.email

    return {
      ...formData,
      customSelections: formattedValue?.filter(Boolean) || [], // Ensure empty array if no selections
      toggleDefaultFields: values?.toggleDefaultFields,
      allQuestions: formattedQuestions || [],
    }
  }

  const onSubmit = (values: any) => {
    const formattedData = formatFormValues(values)
    dispatch(setFormData(formattedData))
    dispatch(setStep(step + 1))
  }

  const handleGoBack = () => {
    dispatch(setStep(step - 1))
  }

  const allQuestions = [
    [
      {
        id: 'firstName',
        enabled: formData?.toggleDefaultFields?.firstName?.enabled || true,
        required: formData?.toggleDefaultFields?.firstName?.required || true,
        label: t('First Name'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'lastName',
        enabled: formData?.toggleDefaultFields?.lastName?.enabled || true,
        required: formData?.toggleDefaultFields?.lastName?.required || true,
        label: t('Last Name'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'salutation',
        enabled: formData?.toggleDefaultFields?.salutation?.enabled || true,
        required: formData?.toggleDefaultFields?.salutation?.required || true,
        label: t('Salutation'),
        type: CUSTOM_FIELD_TYPE.SELECT_BOX,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'professional',
        enabled: formData?.toggleDefaultFields?.professional?.enabled || true,
        required:
          formData?.toggleDefaultFields?.professional?.required || false,
        label: t('Academic Title'),
        type: CUSTOM_FIELD_TYPE.SELECT_BOX,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'jobTitle',
        enabled: formData?.toggleDefaultFields?.jobTitle?.enabled || false,
        required: formData?.toggleDefaultFields?.jobTitle?.required || false,
        label: t('Job title'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'company',
        enabled: formData?.toggleDefaultFields?.company?.enabled || false,
        required: formData?.toggleDefaultFields?.company?.required || true,
        label: t('Company'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      ...(formData?.customSelections
        ? formData?.customSelections?.map(item => ({
            ...item,
            fieldType: FIELD_TYPE.CUSTOM_SELECTION,
          }))
        : []),
    ],
    [
      {
        id: 'email',
        enabled: true,
        required: true,
        label: t('Email'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'address.street',
        enabled:
          formData?.toggleDefaultFields?.address?.street?.enabled || true,
        required:
          formData?.toggleDefaultFields?.address?.street?.required ||
          formData.type === EventType.CLASSIC,
        label: t('Street'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },

      {
        id: 'address.city',
        enabled: formData?.toggleDefaultFields?.address?.city?.enabled || true,
        required:
          formData?.toggleDefaultFields?.address?.city?.required ||
          formData.type === EventType.CLASSIC,
        label: t('City'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'address.zipCode',
        enabled:
          formData?.toggleDefaultFields?.address?.zipCode?.enabled || true,
        required:
          formData?.toggleDefaultFields?.address?.zipCode?.required ||
          formData.type === EventType.CLASSIC,
        label: t('Zip'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'address.country',
        enabled:
          formData?.toggleDefaultFields?.address?.country?.enabled || true,
        required:
          formData?.toggleDefaultFields?.address?.country?.required ||
          formData.type === EventType.CLASSIC,
        label: t('Country'),
        type: CUSTOM_FIELD_TYPE.SELECT_BOX,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },

      {
        id: 'workPhone',
        enabled: formData?.toggleDefaultFields?.workPhone?.enabled || false,
        required: formData?.toggleDefaultFields?.workPhone?.required || false,
        label: t('Work Phone'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'mobilePhone',
        enabled: formData?.toggleDefaultFields?.mobilePhone?.enabled || false,
        required: formData?.toggleDefaultFields?.mobilePhone?.required || false,
        label: t('Mobile phone'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'fax',
        enabled: formData?.toggleDefaultFields?.fax?.enabled || false,
        required: formData?.toggleDefaultFields?.fax?.required || false,
        label: t('Fax'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
    ],
  ]

  const formValue: any = {
    customSelections: formData?.customSelections,
    toggleDefaultFields: formData?.toggleDefaultFields,
    allQuestions:
      formData?.allQuestions?.length > 0
        ? formData?.allQuestions
        : allQuestions,
  }

  const handleAddNewQuestion = (change: any, values: any) => {
    const newQuestions = [
      ...values.allQuestions[0],
      {
        label: '',
        fieldType: FIELD_TYPE.CUSTOM_SELECTION,
        type: CustomFieldType.TEXT,
        required: false,
        enabled: true,
      },
    ]
    change('allQuestions', [newQuestions, ...values.allQuestions.slice(1)])
  }

  const handleDeleteQuestion = (
    change: any,
    values: any,
    columnId: string,
    index: number
  ) => {
    {
      const newItems = values.allQuestions[columnId].filter(
        (_, i) => i !== index
      )

      const newAllQuestions = [...values.allQuestions]
      newAllQuestions[columnId] = newItems
      change('allQuestions', newAllQuestions)
    }
  }

  const handleReorder = (result: any, values: any, change: any) => {
    if (!result.destination) return
    const { source, destination } = result

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = values.allQuestions[source.droppableId]
      const destColumn = values.allQuestions[destination.droppableId]

      const sourceItems = [...sourceColumn]
      const destItems = [...destColumn]
      const [removed] = sourceItems.splice(source.index, 1)
      destItems.splice(destination.index, 0, removed)

      const newAllQuestions = [...values.allQuestions]
      newAllQuestions[source.droppableId] = sourceItems
      newAllQuestions[destination.droppableId] = destItems
      change('allQuestions', newAllQuestions)
    } else {
      const column = values.allQuestions[source.droppableId]
      const copiedItems = [...column]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)

      const newAllQuestions = [...values.allQuestions]
      newAllQuestions[source.droppableId] = copiedItems
      change('allQuestions', newAllQuestions)
    }
  }

  return (
    <div className='space-y-6 px-8 py-4'>
      <div>
        <h3 className='text-lg font-medium'>{t('Form Builder')}</h3>
        <p className='text-sm text-muted-foreground'>
          {t('Customize event form inputs.')}
        </p>
      </div>
      <Separator />
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        keepDirtyOnReinitialize
        initialValues={{ ...formValue }}
        render={({
          handleSubmit,
          values,
          submitting,
          form: { getState, change },
        }: any) => (
          <form onSubmit={handleSubmit}>
            <ChevronLeft
              onClick={() => handleGoBack()}
              className={cn(
                'absolute top-[15px] left-14 cursor-pointer md:top-[28px] md:left-24',
                !matchEditRoute && 'text-white left-5 md:left-8'
              )}
            />

            <div className='mb-3'>
              <FieldArray name='allQuestions'>
                {({ fields, meta: { error } }) => (
                  <>
                    <DragDropContext
                      onDragEnd={result =>
                        handleReorder(result, values, change)
                      }
                    >
                      <div className='flex gap-4'>
                        <div className='mt-3 flex w-full'>
                          {values?.allQuestions?.map(
                            (questions, columnIndex) => {
                              return (
                                <Droppable
                                  key={columnIndex}
                                  droppableId={String(columnIndex)}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                      className='flex flex-col bg-[#f3f3f3] w-1/2 p-[15px]'
                                    >
                                      {questions?.map((item, index) => {
                                        const tempDraggableId = item.label

                                        return (
                                          <Draggable
                                            key={item.id}
                                            draggableId={
                                              item.id || tempDraggableId
                                            }
                                            index={index}
                                          >
                                            {provided => (
                                              <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className='mb-4 p-2 bg-white'
                                              >
                                                <div
                                                  key={item.id}
                                                  className='flex gap-4 justify-between bg-slate-100 px-4 py-6'
                                                >
                                                  <div className='w-[87%]'>
                                                    <FormBuilderInputItem
                                                      name={`allQuestions[${columnIndex}][${index}]`}
                                                      index={index}
                                                      columnId={columnIndex}
                                                    />
                                                  </div>

                                                  {item.fieldType ===
                                                    FIELD_TYPE.CUSTOM_SELECTION && (
                                                    <Trash2
                                                      onClick={() =>
                                                        handleDeleteQuestion(
                                                          change,
                                                          values,
                                                          columnIndex,
                                                          index
                                                        )
                                                      }
                                                      className='w-[20px] text-gray-600 cursor-pointer'
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      })}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              )
                            }
                          )}
                        </div>
                      </div>
                    </DragDropContext>
                    <button
                      type='button'
                      className='-z-1 flex items-center cursor-pointer space-x-2 mt-6 text-primary font-bold'
                      onClick={() => handleAddNewQuestion(change, values)}
                    >
                      <Plus className='w-[20px] mr-1' />
                      {t('Add new questions')}
                    </button>
                  </>
                )}
              </FieldArray>
            </div>

            <div className='flex flex-col md:flex-row gap-4'>
              {matchEditRoute && (
                <SaveChangesNowButton
                  id={id}
                  handleGetFormValues={() => {
                    const values = getState().values
                    return formatFormValues(values)
                  }}
                />
              )}
              <Button type='submit' className='w-full' disabled={submitting}>
                {t('Continue')}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  )
}
