import { CustomFieldOption, EventCustomValue } from 'models'
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useState } from 'react'
import {
  Step,
  defaultToastDisplayTime,
  renderSymbolCurrency,
} from 'utils/helpers'
import { setIsLoading, setStep } from 'store/Events'
import { styled, useHistory, useMutation } from 'utils/adapters'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import { Button } from '@/components/ui/button'
import { COLORS } from 'constants/colors'
import { CUSTOM_FIELD_TYPE } from 'constants/customFieldType'
import ChosenItem from 'components/organisms/EventRegister/DetailCost/ChosenItem'
import CustomFieldFee from 'components/organisms/EventRegister/DetailCost/CustomFieldFee'
import ListChosenItem from 'components/organisms/EventRegister/DetailCost/ListChosenItem'
import RegistrationOverview from 'components/organisms/EventRegister'
import { UPDATE_BOOKING_INFO } from 'graphql/mutations'
import { responsive } from 'assets/scss/mixin'
import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import { setToast } from 'store/Toast'
import { toast } from '@/components/ui/use-toast'
import { usePaymentInfo } from '@/store/zustand/Payments'
import { useTranslation } from 'react-i18next'

const PAYMENT_FEE_COLOR = '#e4edf4'

const DetailCost: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const history = useHistory()

  const {
    totalPrice,
    price,
    event,

    attendeeInfo,
    registeredCustomFields,
  } = useAppSelector(state => state.eventsReducer)
  const attendee = attendeeInfo

  const paid = usePaymentInfo(state => state.amount)

  const fee = totalPrice ? totalPrice - paid : price
  const eventPrice = parseFloat(sessionStorage.getItem('eventPrice') as string)
  const [convertedArray, setConvertedArray] = useState<any>([])
  const [chosenOption, setChosenOption] = useState([])
  const [removedFields, setRemovedFields] = useState([])

  // console.log('fee: ', fee)
  // console.log('paid', paid)
  // console.log('total price: ', totalPrice)
  // console.log('price: ', price)

  const [mutateUpdateBooking] = useMutation(UPDATE_BOOKING_INFO, {
    onCompleted: data => {
      if (data) {
        dispatch(setIsLoading(false))
        dispatch(
          setToast({
            message: t('Your booking information has been updated!'),
            variant: 'success',
            displayTime: defaultToastDisplayTime,
          })
        )
        history.push('/dashboard')
      }
    },

    onError: err => {
      toast({
        title: t('Error'),
        description: t(err.message),
        variant: 'destructive',
      })
    },
  })

  useEffect(() => {
    transformCustomFieldData()
  }, [])

  const transformCustomFieldData = () => {
    const newArr: any = []
    attendee?.registration?.customValues?.forEach((item: any) => {
      const foundIndex = event?.customFields?.findIndex(
        (el: any) => el._id === item.eventCustomField
      )
      if (foundIndex !== -1) {
        const obj = {
          // @ts-ignore
          ...event?.customFields[foundIndex as number],
          options: item.value,
        }
        newArr.push(obj)
      }
    })

    setChosenOption(newArr)
    console.log('newArray: ', newArr)

    const convertedArray = newArr?.filter(
      (item: any) =>
        item.type === CUSTOM_FIELD_TYPE.CHECK_BOX ||
        item.type === CUSTOM_FIELD_TYPE.RADIO_BUTTON ||
        item.type === CUSTOM_FIELD_TYPE.SELECT_BOX
    )

    const listChosenOption: any = []
    convertedArray?.forEach((item: any) => {
      item?.options?.forEach((option: any) => {
        if (option?.fee > 0) {
          const listOptionsFee: any = []
          listOptionsFee.push(option)
          const chosenOption = {
            ...item,
            options: listOptionsFee,
          }
          listChosenOption.push(chosenOption)
        }
      })
    })
    setConvertedArray(listChosenOption)

    // TODO: exists in registeredCF, not exists in newArr -> push to array
    //  exists in registeredCF, exists in newArr but the value (options) is different -> push to array

    const newArrayIds = newArr
      ?.filter(
        (item: any) =>
          item.type === CUSTOM_FIELD_TYPE.CHECK_BOX ||
          item.type === CUSTOM_FIELD_TYPE.RADIO_BUTTON ||
          item.type === CUSTOM_FIELD_TYPE.SELECT_BOX
      )
      // @ts-ignore
      ?.map(({ _id }) => _id)
    const diffCustomFields: any = []
    registeredCustomFields
      ?.filter(
        (item: any) =>
          item.eventCustomField.type === CUSTOM_FIELD_TYPE.CHECK_BOX ||
          item.eventCustomField.type === CUSTOM_FIELD_TYPE.RADIO_BUTTON ||
          item.eventCustomField.type === CUSTOM_FIELD_TYPE.SELECT_BOX
      )
      ?.map((customField: EventCustomValue) => {
        if (newArrayIds.includes(customField?.eventCustomField?._id)) {
          // Todo: option has fee exists in customField, not exists in newCustomField
          // console.log('processing: ', customField)
          const newCustomField = newArr.find(
            (registeredCustomField: { _id: string }) =>
              registeredCustomField?._id === customField?.eventCustomField?._id
          )
          // console.log('newCF: ', newCustomField)

          customField?.value
            ?.map(({ name, fee }) => ({ name, fee }))
            .forEach((option: CustomFieldOption) => {
              // console.log('option: ', option)
              if (
                newCustomField.options
                  // @ts-ignore
                  ?.map(({ name }) => name)
                  ?.includes(option?.name)
              ) {
                // include option:
                // console.log(
                //   'include option: ',
                //   customField.eventCustomField.title,
                //   option
                // )
              } else {
                if (option.fee) {
                  diffCustomFields.push({
                    title: customField?.eventCustomField?.title,
                    _id: customField?.eventCustomField?._id,
                    options: [option],
                  })
                }
              }
            })
        } else {
          diffCustomFields.push(customField)
        }
      })
    // console.log('diff: ', diffCustomFields)
    setRemovedFields(diffCustomFields)
  }

  const removeLocalStorage = () => {
    localStorage.removeItem('inputDate')
    localStorage.removeItem('inputField')
    localStorage.removeItem('selectedFields')
    localStorage.removeItem('selectedRadio')
    localStorage.removeItem('isChecked')
  }

  const onSubmit = () => {
    const {
      _id,
      email,
      status,
      webcastParticipantId,
      webcastUrlJoinLinks,
      ...rest
    } = attendee
    const formData = {
      ...rest,
      address: {
        ...attendee.address,
        // @ts-ignore
        country: attendee.address.country.value,
      },
      registration: {
        customValues: chosenOption.map((item: any) => {
          return {
            eventCustomField: item._id,
            value: item.options,
          }
        }),
      },
    }
    if (event.isBookable) {
      if (fee > 0) {
        dispatch(setStep(Step.Payment))
      } else if (fee < 0) {
        mutateUpdateBooking({
          variables: {
            attendee: formData,
            eventId: event._id,
            orderId: 'refund_request',
          },
        })
      } else {
        mutateUpdateBooking({
          variables: {
            attendee: formData,
            eventId: event._id,
            orderId: 'zero_fee',
          },
        })
      }
    } else {
      dispatch(setIsLoading(true))
      mutateUpdateBooking({
        variables: {
          attendee: formData,
          eventId: event._id,
        },
      })
      removeLocalStorage()
    }
  }

  return (
    <Wrapper>
      <Left isFullWidth={fee === 0}>
        <RegistrationOverview
          attendee={attendee}
          options={chosenOption}
          event={event}
        />
        {fee === 0 && (
          <SubmitButton
            backgroundColor={event?.eventSetting?.primaryColor}
            color={setTextColorBasedOnBackground(
              event?.eventSetting?.primaryColor
            )}
            type='submit'
            onClick={onSubmit}
          >
            {t('Complete Registration')}
          </SubmitButton>
        )}
      </Left>
      <Right isFullWidth={fee === 0}>
        <Cost>
          <Title>{t('Costs')}</Title>
          <ListChosenItem>
            {event?.price !== 0 && (
              <ChosenItem
                title={t('Ticket')}
                price={event?.price as number}
                currency={renderSymbolCurrency(event.currency)}
              />
            )}

            {convertedArray?.map((field: any, index: number) => {
              return (
                <CustomFieldFee
                  key={index}
                  title={field.title}
                  options={field.options}
                  currency={event?.currency as unknown as string}
                />
              )
            })}

            {removedFields?.map((field: any, index: number) => {
              return (
                <CustomFieldFee
                  key={index}
                  title={field.title}
                  options={field.options}
                  currency={event?.currency as unknown as string}
                  isPaid
                />
              )
            })}

            {paid && (
              <ChosenItem
                title={t('Paid')}
                price={paid}
                currency={renderSymbolCurrency(event.currency)}
                isPaid={true}
              />
            )}

            {fee > 0 && eventPrice !== fee && (
              <TotalPrice
                title={t('Total price')}
                price={fee}
                currency={renderSymbolCurrency(event.currency)}
              />
            )}
          </ListChosenItem>
          <SubmitButton
            backgroundColor={event?.eventSetting?.primaryColor}
            color={setTextColorBasedOnBackground(
              event?.eventSetting?.primaryColor
            )}
            type='submit'
            onClick={onSubmit}
          >
            {event.isBookable && fee
              ? fee > 0
                ? `${t('Pay')} ${renderSymbolCurrency(event.currency)}${fee}`
                : `${t('Refund')} ${renderSymbolCurrency(
                    event.currency
                  )}${Math.abs(fee)}`
              : t('Complete Registration')}
          </SubmitButton>
        </Cost>
      </Right>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  padding: 2%;
  box-sizing: border-box;
  gap: 15px;
  ${responsive.lg`
    flex-direction: column;
  `}
`

const Grid = styled.div`
  display: flex;
  flex-direction: column;
`

const Right = styled.div<{ isFullWidth?: boolean }>`
  width: calc(30% - 15px);
  display: ${props => (props.isFullWidth ? 'none' : 'block')};
  ${responsive.lg`
    width: 100%;
  `}
`

const Left = styled(Grid)<{ isFullWidth?: boolean }>`
  gap: 15px;
  width: 70%;
  margin: ${props => (props.isFullWidth ? '0 auto' : '0')};
  ${responsive.lg`
    width: 100%;
  `}
`

const Cost = styled(Grid)`
  gap: 15px;
  max-width: 600px;
  ${responsive.lg`
    max-width: 100%;
  `}
`

const Title = styled.span`
  color: ${COLORS.BLUE};
  font-size: 2em;
  padding-bottom: 10px;
  ${responsive.sm`
    font-size: 1.5em;
  `}
`

const TotalPrice = styled(ChosenItem)`
  background-color: ${PAYMENT_FEE_COLOR};

  span {
    color: ${COLORS.BLUE};
    font-weight: bold;
  }
`

const SubmitButton = styled(Button)<{
  backgroundColor?: string
  color?: string
}>`
  width: 100%;
  padding: 15px;
  margin: 15px 0;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  align-self: center;
`

export default DetailCost
