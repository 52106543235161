export enum CUSTOM_FIELD_TYPE {
  TEXT = 'TEXT',
  CHECK_BOX = 'CHECK_BOX',
  SELECT_BOX = 'SELECT_BOX',
  DATE_TIME = 'DATE_TIME',
  RADIO_BUTTON = 'RADIO_BUTTON',
}

export const customFieldType = [
  {
    label: 'TEXT',
    value: 'TEXT',
  },
  {
    label: 'CHECK_BOX',
    value: 'CHECK_BOX',
  },
  {
    label: 'SELECT_BOX',
    value: 'SELECT_BOX',
  },
  {
    label: 'DATE_TIME',
    value: 'DATE_TIME',
  },
  {
    label: 'RADIO_BUTTON',
    value: 'RADIO_BUTTON',
  },
]

export const FIELD_TYPE = {
  CUSTOM_SELECTION: 'customSelection',
  DEFAULT_FIELD: 'defaultField',
}
