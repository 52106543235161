import Quill from 'quill'

const fonts = Quill.import('attributors/style/font')
export const quillFonts = [
  'arial',
  'calibri',
  'courier',
  'georgia',
  'lucida',
  'open',
  'roboto',
  'tahoma',
  'trebuchet',
  'verdana',
  'Graphik',
  ...fonts.whitelist,
]

fonts.whitelist = quillFonts
Quill.register(fonts, true)
