import { FC, useState } from 'react'
import { UploadStep } from './steps/UploadStep'
import { StepIndicator } from './components/StepIndicator'
import { ImportStep } from './steps/ImportStep'
import { MapStep } from './steps/MapStep'
import { QuestionField } from '@/models'
import { useAppSelector } from '@/utils/hooks'
import { useMutation, useParams } from '@/utils/adapters'
import { IMPORT_ATTENDEES } from '@/graphql/mutations'
import { toast } from '@/components/ui/use-toast'
import { useTranslation } from 'react-i18next'

export type Step = 'upload' | 'map' | 'import'

export const ATTENDEE_DEFAULT_PROPERTIES = [
  { key: 'firstName', label: 'First Name' },
  { key: 'lastName', label: 'Last Name' },
  { key: 'email', label: 'Email' },
  { key: 'mobilePhone', label: 'Phone' },
  { key: 'company', label: 'Company' },
  { key: 'jobTitle', label: 'Title' },
  { key: 'address.street', label: 'Street' },
  { key: 'address.city', label: 'City' },
  { key: 'address.zipCode', label: 'Zip' },
  { key: 'address.country', label: 'Country' },
  { key: 'workPhone', label: 'Work Phone' },
  { key: 'fax', label: 'Fax' },
  { key: 'salutation', label: 'Salutation' },
  { key: 'professional', label: 'Academic Title' },
]

export const ImportAttendees: FC = () => {
  const { id: eventId } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = useState<Step>('upload')
  const [file, setFile] = useState<File | null>(null)
  const [columnMapping, setColumnMapping] = useState<Record<string, string>>({})
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [selectedCharset, setSelectedCharset] = useState('UTF-8')
  const [importResults, setImportResults] = useState<{
    succeeded: any[]
    failed: any[]
  }>({ succeeded: [], failed: [] })
  const [isImporting, setIsImporting] = useState<boolean>(false)

  const { eventDetail: event } = useAppSelector(state => state.eventsReducer)
  const { allQuestions } = event

  const [mutateImportAttendees] = useMutation(IMPORT_ATTENDEES, {
    onCompleted({ importAttendees }) {
      setIsImporting(false)
      const { createdAttendees, errorAttendees } = importAttendees

      setImportResults({
        succeeded: createdAttendees,
        failed: errorAttendees,
      })

      toast({
        description: createdAttendees.length
          ? t('Import attendee success')
          : t('Import attendee error'),
        variant: createdAttendees?.length ? 'default' : 'destructive',
      })
    },
    onError: err => {
      setIsImporting(false)
      err.graphQLErrors.map(({ message }) => {
        toast({
          title: t('Error'),
          description: t(message),
          variant: 'destructive',
        })
      })
    },
  })

  const getEventQuestions = (allQuestions: QuestionField[][]) => {
    const defaultQuestions = []
    const customQuestions: Record<string, string>[] = []

    if (!allQuestions?.length) {
      return [...defaultQuestions]
    }

    for (const row of allQuestions) {
      for (const question of row) {
        // filter only the customSelection questions, not defaultField and the question type is TEXT
        if (
          question.fieldType === 'customSelection' &&
          question.type === 'TEXT'
        ) {
          customQuestions.push({
            key: `customselection.${question.id}`,
            label: question.label,
          })
        }
      }
    }

    return [...defaultQuestions, ...customQuestions]
  }

  const handleSubmit = (processedData: any) => {
    setIsImporting(true)
    if (processedData) {
      const attendees = processedData?.data.map(row => {
        // Extract address fields
        const address = {
          street: row['address']?.street || row['address.street'],
          zipCode: row['address']?.zipCode || row['address.zipCode'],
          country: row['address']?.country || row['address.country'],
          city: row['address']?.city || row['address.city'],
        }

        // Extract customSelection fields
        const customSelection: Record<string, string> = {}
        Object.entries(row).forEach(([key, value]) => {
          if (key.startsWith('customselection.')) {
            const fieldId = key.split('.')[1]
            customSelection[fieldId] = value as string
          }
        })

        // Create new object with restructured data
        const newRow = {
          ...row,
          address,
          customSelection,
          // Remove the dot notation fields
          'address.street': undefined,
          'address.zipCode': undefined,
          'address.country': undefined,
          'address.city': undefined,
          'street': undefined,
          'zipCode': undefined,
          'country': undefined,
          'city': undefined,
        }

        // Remove all customselection.* fields from root
        Object.keys(newRow).forEach(key => {
          if (key.startsWith('customselection.')) {
            delete newRow[key]
          }
        })

        return newRow
      })
      mutateImportAttendees({
        variables: {
          eventId,
          attendees,
          sendMailAfterImport: processedData?.sendEmail,
        },
      })
      // Add your API call or data processing here
    }
  }

  const handleOnClick = (event: React.MouseEvent<HTMLInputElement>) => {
    // Reset file input if needed
    const input = event.target as HTMLInputElement
    input.value = ''
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile && selectedFile.type === 'text/csv') {
      setFile(selectedFile)
      setIsUploading(true)

      // Simulate file upload progress
      let progress = 0
      const interval = setInterval(() => {
        progress += 5
        setUploadProgress(progress)
        if (progress >= 100) {
          clearInterval(interval)
        }
      }, 100)
    } else {
      // Handle invalid file type
      console.error('Please select a valid CSV file')
    }
  }

  const handleCancel = () => {
    setIsUploading(false)
    setUploadProgress(0)
    setFile(null)
  }

  const handleNext = () => {
    const steps: Step[] = ['upload', 'map', 'import']
    const currentIndex = steps.indexOf(currentStep)
    if (currentIndex < steps.length - 1) {
      // Validate current step before proceeding
      if (validateCurrentStep()) {
        setCurrentStep(steps[currentIndex + 1])
      }
    }
  }

  const handleBack = () => {
    const steps: Step[] = ['upload', 'map', 'import']
    const currentIndex = steps.indexOf(currentStep)
    if (currentIndex > 0) {
      setCurrentStep(steps[currentIndex - 1])
    }
  }

  const validateCurrentStep = (): boolean => {
    switch (currentStep) {
      case 'upload':
        return !!file
      case 'map':
        // Add validation for mapping step
        return true
      case 'import':
        // Add validation for import step
        return true
      default:
        return false
    }
  }

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 'upload':
        return (
          <UploadStep
            handleOnChange={handleOnChange}
            handleOnClick={handleOnClick}
            onNext={handleNext}
            isUploading={isUploading}
            uploadProgress={uploadProgress}
            fileName={file?.name}
            onCancel={handleCancel}
            selectedCharset={selectedCharset}
            onCharsetChange={setSelectedCharset}
          />
        )
      case 'map':
        return (
          <MapStep
            onNext={handleNext}
            onBack={handleBack}
            file={file}
            onColumnMappingChange={setColumnMapping}
            columnMapping={columnMapping}
            attendeeProperties={[
              ...ATTENDEE_DEFAULT_PROPERTIES,
              ...getEventQuestions(allQuestions),
            ]}
          />
        )
      case 'import':
        return (
          <ImportStep
            file={file}
            columnMapping={columnMapping}
            onBack={handleBack}
            onSubmit={handleSubmit}
            charset={selectedCharset}
            attendeeProperties={[
              ...ATTENDEE_DEFAULT_PROPERTIES,
              ...getEventQuestions(allQuestions),
            ]}
            importResults={importResults}
            isImporting={isImporting}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className='mx-auto p-[40px]'>
      <StepIndicator currentStep={currentStep} />
      {renderCurrentStep()}
    </div>
  )
}

export default ImportAttendees
