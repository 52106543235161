import { CUSTOM_FIELD_TYPE, FIELD_TYPE } from '@/constants/customFieldType'
import { FC, useEffect, useState } from 'react'
import {
  Field,
  Form,
  FormSpy,
  styled,
  useLazyQuery,
  useQuery,
} from 'utils/adapters'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { GET_PROFESSIONALS, GET_SALUTATIONS } from 'graphql/queries'
import {
  Step,
  composeValidators,
  emailValidation,
  getCountryByName,
  getListCountriesByLanguage,
  maxLengthValidation,
  postCodeValidation,
  requiredValidation,
  trimWhiteSpace,
} from 'utils/helpers'
import { setAttendeeInfo, setStep } from 'store/Events'
import { useAppDispatch, useAppSelector } from 'utils/hooks'

import { Button } from '@/components/ui/button'
import { COLORS } from '@/constants/colors'
import { CustomSelection } from '../../../components/AttendeeInfo/CustomSelection'
import { Environment } from 'environments'
import { EventType } from '@/constants/events'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import Loading from 'components/atoms/Loading'
import ReactSelectAdapter from 'components/atoms/Select'
import axios from 'axios'
import { getLanguage } from '@/constants/language'
import { isEmpty } from 'lodash'
import { setTextColorBasedOnBackground } from '@/utils/helpers/colors'
import { useTranslation } from 'react-i18next'

const AttendeeForm: FC = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const appLanguage = localStorage.getItem('i18nextLng')
  const { event, attendeeInfo } = useAppSelector(state => state.eventsReducer)

  const [salutationList, setSalutationList] = useState<
    { key: string; value: string }[]
  >([])

  useEffect(() => {
    getSalutationList()
  }, [appLanguage])

  const [
    getSalutationList,
    { data: salutationData, loading: loadingSalutation },
  ] = useLazyQuery(GET_SALUTATIONS, {
    onCompleted: () => {
      setSalutationList(salutationData?.fetchSalutations?.data)
    },
    onError: ({ message }) => {
      console.log(message)
    },
    variables: {
      language: getLanguage(appLanguage),
    },
  })

  const { data: professionalData, loading: professionalLoading } = useQuery(
    GET_PROFESSIONALS,
    {
      onError: ({ message }) => {
        console.log(message)
      },
    }
  )

  const onSubmit = () => {
    sessionStorage.setItem('eventPrice', event?.price?.toString() as string)

    const attendee = JSON.parse(
      sessionStorage.getItem('attendee_info') as string
    )
    dispatch(setAttendeeInfo(attendee))

    if (event?.customFields?.length === 0) {
      if (!event.isBookable) {
        dispatch(setStep(Step.DetailCost))
      } else {
        axios
          .post(`${Environment.serverUrl}/payments/customers`, {
            email: attendeeInfo.email,
            name: `${attendeeInfo.firstName} ${attendeeInfo.lastName}`,
            phone: attendeeInfo.mobilePhone,
            metadata: {
              email: attendeeInfo.email,
              firstName: attendeeInfo.firstName,
              lastName: attendeeInfo.lastName,
              mobilePhone: attendeeInfo.mobilePhone,
            },
          })
          .then(response => {
            localStorage.setItem(
              'stripeCustomerInfo',
              JSON.stringify(response.data)
            )
          })
          .catch(error => {
            console.log(error)
          })

        dispatch(setStep(Step.DetailCost))
      }
    } else {
      dispatch(setStep(Step.AttendeeCustomField))
    }
  }

  const requiredTextField = composeValidators(
    requiredValidation,
    maxLengthValidation
  )

  const salutationOptions = salutationList?.map(({ key, value }) => ({
    label: value || '-',
    value: key || '-',
  }))

  const professionalOptions = professionalData?.fetchProfessionals?.data?.map(
    ({ key, value }) => ({
      label: value || '-',
      value: key || '-',
    })
  )

  const initialValues = {
    ...attendeeInfo,
    address: {
      ...attendeeInfo?.address,
      country: {
        label: getCountryByName(attendeeInfo?.address?.country, appLanguage)
          ?.name,
        value: getCountryByName(attendeeInfo?.address?.country, appLanguage)
          ?.name,
      },
    },
  }

  const validateQuestion = (question: any) => {
    switch (question.id) {
      case 'email':
        return composeValidators(
          requiredValidation,
          maxLengthValidation,
          emailValidation
        )
      case 'postCode':
        return question?.required
          ? composeValidators(
              requiredValidation,
              maxLengthValidation,
              postCodeValidation
            )
          : composeValidators(maxLengthValidation, postCodeValidation)

      case 'salutation':
      case 'professional':
        return question?.required ? requiredValidation : undefined
      default:
        return question?.required
          ? composeValidators(requiredTextField, maxLengthValidation)
          : maxLengthValidation
    }
  }

  const renderQuestion = (question: any) => {
    if (
      question?.id === 'salutation' &&
      !loadingSalutation &&
      salutationList?.length &&
      question?.enabled
    ) {
      return (
        <div className='relative flex flex-col' key={question?.id}>
          <Label className='mb-2'>
            {t(question?.label)}
            {question?.required !== false && (
              <span className='leading-none'>*</span>
            )}
          </Label>
          <Field
            name='salutation'
            validate={validateQuestion(question)}
            component={ReactSelectAdapter}
            placeholder={''}
            isSearchable={false}
            options={salutationOptions}
            format={(value: any) => {
              return {
                label:
                  salutationOptions.find(item => item.value === value)?.label ||
                  '',
                value,
              }
            }}
            parse={(option: any) => option.value}
          />
        </div>
      )
    }
    if (
      question?.id === 'professional' &&
      !professionalLoading &&
      question?.enabled &&
      professionalData?.fetchProfessionals?.data?.length
    ) {
      return (
        <div className='relative flex flex-col' key={question?.id}>
          <Label className='mb-2'>
            {t(question?.label)}
            {question?.required !== false && (
              <span className='leading-none'>*</span>
            )}
          </Label>
          <Field
            name='professional'
            validate={validateQuestion(question)}
            component={ReactSelectAdapter}
            placeholder={''}
            isSearchable={false}
            options={professionalOptions}
            format={(value: any) => ({
              label:
                professionalOptions.find(item => item.value === value)?.label ||
                '',
              value,
            })}
            parse={(option: any) => option.value}
          />
        </div>
      )
    }
    if (question.id === 'address.country' && question.enabled) {
      return (
        <div className='relative flex flex-col' key={question.id}>
          <Label className='mb-2'>
            {t(question?.label)}{' '}
            {question?.required && <span className='leading-none'>*</span>}
          </Label>
          <Field
            className='select-country'
            name='address.country'
            validate={validateQuestion(question)}
            component={ReactSelectAdapter}
            placeholder={t('Please select your country')}
            options={getListCountriesByLanguage(appLanguage).map(country => ({
              label: country.name,
              value: country.name,
              code: country.code,
            }))}
          />
        </div>
      )
    }
    if (question.fieldType === FIELD_TYPE.DEFAULT_FIELD) {
      return (
        question.enabled && (
          <Field
            name={question.id}
            type={question.type}
            key={question.id}
            validate={validateQuestion(question)}
            format={trimWhiteSpace}
            formatOnBlur
          >
            {({ input, meta }: any) => (
              <FormItem>
                <FormLabel meta={meta} isRequired={!!question?.required}>
                  {t(question.label)}
                </FormLabel>
                <Input
                  {...input}
                  isRequired={question?.required}
                  meta={meta}
                  maxLength='255'
                />
              </FormItem>
            )}
          </Field>
        )
      )
    }
    if (question.fieldType === FIELD_TYPE.CUSTOM_SELECTION) {
      return (
        <CustomSelection
          item={question}
          eventPrimaryColor={event?.eventSetting?.primaryColor}
        />
      )
    }
  }
  const allQuestions = [
    [
      {
        id: 'firstName',
        enabled: event?.toggleDefaultFields?.firstName?.enabled || true,
        required: event?.toggleDefaultFields?.firstName?.required || true,
        label: t('First Name'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'lastName',
        enabled: event?.toggleDefaultFields?.lastName?.enabled || true,
        required: event?.toggleDefaultFields?.lastName?.required || true,
        label: t('Last Name'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'salutation',
        enabled: event?.toggleDefaultFields?.salutation?.enabled || true,
        required: event?.toggleDefaultFields?.salutation?.required || true,
        label: t('Salutation'),
        type: CUSTOM_FIELD_TYPE.SELECT_BOX,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'professional',
        enabled: event?.toggleDefaultFields?.professional?.enabled || true,
        required: event?.toggleDefaultFields?.professional?.required || false,
        label: t('Academic Title'),
        type: CUSTOM_FIELD_TYPE.SELECT_BOX,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'jobTitle',
        enabled: event?.toggleDefaultFields?.jobTitle?.enabled || false,
        required: event?.toggleDefaultFields?.jobTitle?.required || false,
        label: t('Job title'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'company',
        enabled: event?.toggleDefaultFields?.company?.enabled || false,
        required: event?.toggleDefaultFields?.company?.required || true,
        label: t('Company'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      ...(event?.customSelections
        ? event?.customSelections?.map(item => ({
            ...item,
            fieldType: FIELD_TYPE.CUSTOM_SELECTION,
          }))
        : []),
    ],
    [
      {
        id: 'email',
        enabled: true,
        required: true,
        label: t('Email'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'address.street',
        enabled: event?.toggleDefaultFields?.address?.street?.enabled || true,
        required:
          event?.toggleDefaultFields?.address?.street?.required ||
          event?.type === EventType.CLASSIC,
        label: t('Street'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },

      {
        id: 'address.city',
        enabled: event?.toggleDefaultFields?.address?.city?.enabled || true,
        required:
          event?.toggleDefaultFields?.address?.city?.required ||
          event?.type === EventType.CLASSIC,
        label: t('City'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'address.zipCode',
        enabled: event?.toggleDefaultFields?.address?.zipCode?.enabled || true,
        required:
          event?.toggleDefaultFields?.address?.zipCode?.required ||
          event?.type === EventType.CLASSIC,
        label: t('Zip'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'address.country',
        enabled: event?.toggleDefaultFields?.address?.country?.enabled || true,
        required:
          event?.toggleDefaultFields?.address?.country?.required ||
          event?.type === EventType.CLASSIC,
        label: t('Country'),
        type: CUSTOM_FIELD_TYPE.SELECT_BOX,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },

      {
        id: 'workPhone',
        enabled: event?.toggleDefaultFields?.workPhone?.enabled || false,
        required: event?.toggleDefaultFields?.workPhone?.required || false,
        label: t('Work Phone'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'mobilePhone',
        enabled: event?.toggleDefaultFields?.mobilePhone?.enabled || false,
        required: event?.toggleDefaultFields?.mobilePhone?.required || false,
        label: t('Mobile phone'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
      {
        id: 'fax',
        enabled: event?.toggleDefaultFields?.fax?.enabled || false,
        required: event?.toggleDefaultFields?.fax?.required || false,
        label: t('Fax'),
        type: CUSTOM_FIELD_TYPE.TEXT,
        fieldType: FIELD_TYPE.DEFAULT_FIELD,
      },
    ],
  ]
  const columnsQuestion1 = event?.allQuestions?.[0] || allQuestions[0]
  const columnsQuestion2 = event?.allQuestions?.[1] || allQuestions[1]

  if (isEmpty(attendeeInfo) || loadingSalutation) {
    return <Loading />
  }

  return (
    <>
      <div className='py-10'>
        <div className='container mx-auto px-4'>
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  className='container mx-auto px-4 py-2 flex-wrap justify-around gap-4 md:gap-6 md:py-6'
                >
                  <FormSpy
                    subscription={{ values: true, valid: true }}
                    onChange={(state: any) => {
                      const { values } = state
                      sessionStorage.setItem(
                        'attendee_info',
                        JSON.stringify(values)
                      )
                    }}
                  />
                  <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
                    <div className='col-span-1 lg:w-4/5 w-full space-y-4 lg:mx-auto'>
                      {columnsQuestion1?.map((question: any) =>
                        renderQuestion(question)
                      )}
                    </div>
                    <div className='col-span-1 lg:w-4/5 w-full space-y-4 lg:mx-auto'>
                      {columnsQuestion2?.map((question: any) =>
                        renderQuestion(question)
                      )}
                      <div className='space-y-6 lg:col-start-2'>
                        <SubmitButton
                          primaryColor={event?.eventSetting?.primaryColor}
                          color={setTextColorBasedOnBackground(
                            event?.eventSetting?.primaryColor
                          )}
                          type='submit'
                          className='w-full'
                          disabled={submitting}
                        >
                          {t('Continue')}
                        </SubmitButton>
                      </div>
                    </div>
                  </div>
                </form>
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

const SubmitButton = styled(Button)<{
  primaryColor?: string
  color?: string
}>`
  background-color: ${props => props.primaryColor || COLORS.BLUE};
  color: ${props => props.color};
`

export default AttendeeForm
