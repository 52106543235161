import {
  ActionRefundCancelBookingStatus,
  STATUS,
  defaultTimezone,
  getEventDateByTimeZone,
  getTimeByTimeZone,
  renderStatusLabel,
} from 'utils/helpers'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronRight,
  ChevronsUpDownIcon,
  Download,
} from 'lucide-react'
import { CHANGE_ATTENDANT_STATUS, REMOVE_ATTENDEE } from '@/graphql/mutations'
import {
  Cancellation,
  ICustomSelection,
  IEvent,
  Attendee as TAttendee,
} from 'models'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { FC, useEffect, useMemo, useState } from 'react'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from '@/components/ui/menubar'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { cn, useExportEventAttendee } from '@/lib/utils'
import {
  getEventListStatus,
  useStatusManagement,
} from '@/store/zustand/StatusManagement'

import { ADMIN_GET_EVENT_BY_ID } from '@/graphql/queries'
import { ActionIcon } from 'components/styles'
import ActionRefundCancelBookingModal from '@/components/organisms/EventDetail/components/AttendantsDetails/ActionRefundCancelBookingModal'
import AttendeeSheet from '@/components/pages/EventAttendeeList/AttendeeDetailModal'
import { BasicTableSearchInput } from 'components/molecules/BasicTableSearchInput'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import ExportAttendeeModal from './ExportAttendeeModal'
import { FIELD_TYPE } from '@/constants/customFieldType'
import FilterColumnModal from '@/components/organisms/FilterModal'
import Modal from 'components/molecules/Modal'
import Paginator from './paginator'
import Permission from 'components/atoms/Permission'
import { RemoveIcon } from 'assets/images'
import { SystemFieldType } from '@/constants/systemField'
import { USER_SCOPE } from 'constants/userRole'
import { UpdateAttendeeStatusModal } from './UpdateAttendeeStatusModal'
import { getAttendeeSystemField } from '@/lib/utils/attendeeFields'
import { t } from 'i18next'
import { toast } from '@/components/ui/use-toast'
import { updateAttendeeDetail } from '@/store/zustand/Attendees'
import { useAppSelector } from 'utils/hooks'
import { useMutation } from 'utils/adapters'
import { useTranslation } from 'react-i18next'

const listStatusExport = [
  {
    status: STATUS.imported,
    label: t('Imported'),
  },
  {
    status: STATUS.registered,
    label: t('Registered'),
  },
  {
    status: STATUS.checked_in,
    label: t('Checked In'),
  },
  {
    status: STATUS.invited,
    label: t('Invited'),
  },
  {
    status: STATUS.invitation_sent_out,
    label: t('Invitation sent out'),
  },
]

type AttendantListType = {
  event: IEvent
}

type ColumnSort = {
  id: string
  desc: boolean
}
type SortingState = ColumnSort[]

// Add this constant at the top of the file
const COLUMN_VISIBILITY_KEY = 'attendeeTableColumns'

const Attendees: FC<AttendantListType> = ({ event }) => {
  const { lang } = useAppSelector(state => state.appReducer)
  const { t } = useTranslation()
  const tz = event?.timezone || defaultTimezone
  const [attendee, setAttendee] = useState<TAttendee>({} as TAttendee)
  const [selectedAttendee, setSelectedAttendee] = useState<string>('')
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false)
  const [isShowActionRefundCancelModal, setIsShowActionRefundCancelModal] =
    useState<boolean>(false)
  const [openModalExport, setOpenModalExport] = useState(false)
  const [openModalDetail, setOpenModalDetail] = useState(false)
  const [exportStatus, setExportStatus] = useState<string | undefined>(
    undefined
  )
  const [exportLoading, setExportLoading] = useState(false)
  const [updateDetailLoading, setUpdateDetailLoading] = useState(false)
  const [actionRefundWaitingInfo, setActionRefundWaitingInfo] = useState<{
    action: ActionRefundCancelBookingStatus
    attendeeEmail: string
    cancellation: Cancellation
    registrationCodes: string[]
  }>({
    action: ActionRefundCancelBookingStatus.Refuse,
    attendeeEmail: '',
    cancellation: {
      isCancelled: false,
      cancellationDate: new Date(),
      cancellationFee: 0,
      refundFee: 0,
      cancellationReason: '',
    },
    registrationCodes: [],
  })

  const [sorting, setSorting] = useState<SortingState>([])

  const eventListStatus = useStatusManagement(state => state.eventListStatus)

  const [globalFilter, setGlobalFilter] = useState('')

  const [attendeeList, setAttendeeList] = useState<TAttendee[]>([])

  const numberOfCheckedInAttendees = attendeeList.reduce((counter, obj) => {
    if (obj.status === STATUS.checked_in) counter += 1
    return counter
  }, 0)
  const numberOfRegisteredAttendees = attendeeList.reduce((counter, obj) => {
    if (obj.status === STATUS.registered) counter += 1
    return counter
  }, 0)

  const eventCustomStatusOptions = eventListStatus.map(status => ({
    value: status.name,
    label: status.name,
    quantity: attendeeList.reduce((counter, obj) => {
      if (obj.status === status.name) counter += 1
      return counter
    }, 0),
  }))

  const attendeeStatusOptions = Object.keys(STATUS).map(key => ({
    value: STATUS[key as keyof typeof STATUS],
    label: renderStatusLabel(STATUS[key]),
    quantity: attendeeList.reduce((counter, obj) => {
      if (obj.status === STATUS[key]) counter += 1
      return counter
    }, 0),
  }))

  const eventCustomQuestions =
    event?.allQuestions?.flatMap(questionsGroup =>
      questionsGroup.filter(
        question => question.fieldType === FIELD_TYPE.CUSTOM_SELECTION
      )
    ) || event?.customSelections

  // Update visibleColumns initialization to use localStorage
  const [visibleColumns, setVisibleColumns] = useState<Record<string, boolean>>(
    () => {
      const saved = localStorage.getItem(COLUMN_VISIBILITY_KEY)
      return saved ? JSON.parse(saved) : {}
    }
  )

  // Add effect to save to localStorage when visibleColumns changes
  useEffect(() => {
    if (Object.keys(visibleColumns).length > 0) {
      localStorage.setItem(
        COLUMN_VISIBILITY_KEY,
        JSON.stringify(visibleColumns)
      )
    }
  }, [visibleColumns])

  // Define base columns with system fields
  const baseColumns = useMemo<ColumnDef<TAttendee, any>[]>(
    () => [
      {
        header: t('No.'),
        cell: ({ row }) => <b>{row?.index + 1}</b>,
        size: 50,
      },
      {
        id: 'name',
        header: t('Name'),
        cell: ({ row }) => {
          const attendee = row.original
          const firstName = getAttendeeSystemField(
            attendee,
            event,
            SystemFieldType.FirstName,
            'firstName'
          )
          const lastName = getAttendeeSystemField(
            attendee,
            event,
            SystemFieldType.LastName,
            'lastName'
          )
          return <b>{`${firstName || ''} ${lastName || ''}`}</b>
        },
        accessorFn: row => {
          const firstName = getAttendeeSystemField(
            row,
            event,
            SystemFieldType.FirstName,
            'firstName'
          )
          const lastName = getAttendeeSystemField(
            row,
            event,
            SystemFieldType.LastName,
            'lastName'
          )
          return `${firstName} ${lastName}`
        },
        size: 180,
      },
      {
        id: 'email',
        header: t('Email'),
        accessorKey: 'email',
        cell: ({ row }) => <b>{row?.original?.email}</b>,
        size: 350,
      },
      {
        header: t('Hotel'),
        accessorKey: 'hotel',
        cell: ({ row }) => <b>{(row.original as any).hotel || ''}</b>,
        size: 350,
      },
      {
        header: t('Registration'),
        size: 300,
        accessorFn: (row: any) => {
          const { utcOffset, time: registrationTime } = getTimeByTimeZone(
            row?.registration?.registrationDate,
            lang,
            tz
          )
          return `${getEventDateByTimeZone(
            row?.registration?.registrationDate,
            lang,
            tz
          )} - ${registrationTime} (GMT${utcOffset})`
        },
        enableSorting: true,
        sortingFn: (rowA, rowB) => {
          const dateA = new Date(rowA?.original?.registration?.registrationDate)
          const dateB = new Date(rowB?.original?.registration?.registrationDate)
          if (dateA && dateB) {
            return dateA.getTime() - dateB.getTime()
          }
          return 0
        },
      },
      {
        header: t('Status'),
        size: 200,
        accessorKey: 'status',
        cell: ({ row }) => {
          const attendee = row.original
          return (
            <div className='min-w-[150px]'>
              <Permission scope={USER_SCOPE.ATTENDEE_CHECKIN}>
                {!event.isExpired && (
                  <UpdateAttendeeStatusModal
                    attendee={attendee}
                    status={attendee?.status}
                    statusList={attendeeStatusOptions}
                    customStatus={eventListStatus}
                    updateStatus={handleUpdateStatus}
                  />
                )}
              </Permission>
            </div>
          )
        },
        Filter: ({ column }) => (
          <FilterColumnModal
            column={column}
            filterData={[...attendeeStatusOptions, ...eventCustomStatusOptions]}
          />
        ),
        enableSorting: false,
        filterFn: (row, id, value) => {
          if (!value || value.length === 0) {
            return true
          }
          return value.includes(row.getValue(id))
        },
      },
    ],
    [
      event.customSelections,
      t,
      eventListStatus,
      attendeeStatusOptions,
      eventCustomStatusOptions,
      lang,
    ]
  )

  // Generate columns from custom selections
  const customColumns = useMemo(
    () => {
      const customQuestions =
        event?.customSelections?.map(selection => ({
          id: `custom_${selection.id}`,
          header: selection.systemField
            ? `${selection.label} (${t(`${selection.systemField}`)})`
            : selection.label,
          accessorFn: (row: TAttendee) => row.customSelection?.[selection.id],
          cell: ({ row }) => (
            <b>{row.original.customSelection?.[selection.id]}</b>
          ),
          size: 200,
        })) || []

      const customQuestionsFromAllQuestions =
        event?.allQuestions?.flatMap(questionsGroup =>
          questionsGroup.filter(
            question => question.fieldType === FIELD_TYPE.CUSTOM_SELECTION
          )
        ) || []

      if (customQuestionsFromAllQuestions.length > 0) {
        return customQuestionsFromAllQuestions.map(question => ({
          id: `custom_${question.id}`,
          header: question.label,
          accessorFn: (row: TAttendee) => row.customSelection?.[question.id],
          cell: ({ row }) => (
            <b>{row.original.customSelection?.[question.id]}</b>
          ),
          size: 200,
        }))
      }
      return customQuestions
    }, // Add default empty array here
    [event?.customSelections, event?.allQuestions, t]
  )

  // Combine and filter columns based on visibility
  const columns = useMemo(() => {
    const allColumns = [
      ...baseColumns,
      ...customColumns,
      {
        header: ' ',
        size: 80,
        cell: ({ row }) => {
          const attendee = row.original

          return (
            <div className='flex gap-2'>
              <ChevronRight
                className={cn('cursor-pointer m-auto h-9 w-9')}
                onClick={() => {
                  setOpenModalDetail(true)
                  setAttendee(attendee)
                }}
              />
              <ActionIcon
                source={RemoveIcon}
                onClick={() => handleDeleteAttendee(row.original?._id)}
              />
            </div>
          )
        },
      },
    ]
    if (Object.keys(visibleColumns).length === 0) {
      // Initialize visible columns on first render
      const initialVisibility = allColumns.reduce((acc, column) => {
        acc[column.id!] = true
        return acc
      }, {} as Record<string, boolean>)
      setVisibleColumns(initialVisibility)
      return allColumns
    }
    return allColumns.filter(column => visibleColumns[column.id!])
  }, [baseColumns, customColumns, visibleColumns])

  // Column visibility toggle menu
  const ColumnVisibilityMenu = () => (
    <Menubar className='mb-4'>
      <MenubarMenu>
        <MenubarTrigger>{t('show_hide_columns')}</MenubarTrigger>
        <MenubarContent>
          {[...baseColumns, ...customColumns].map(column => (
            <MenubarItem key={column.id}>
              <Checkbox
                id={column.id}
                checked={visibleColumns[column.id!]}
                onCheckedChange={checked => {
                  setVisibleColumns(prev => ({
                    ...prev,
                    [column.id!]: !!checked,
                  }))
                }}
              />
              <label htmlFor={column.id} className='ml-2'>
                {typeof column.header === 'string' ? column.header : column.id}
              </label>
            </MenubarItem>
          ))}
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )

  useEffect(() => {
    getEventListStatus(event?._id)
  }, [])

  useEffect(() => {
    const mappedAttendees = event?.attendees.map(attendee => ({
      ...attendee,
      ...(attendee.additionalFields?.hotel && {
        hotel: attendee.additionalFields?.hotel,
      }),
    }))
    setAttendeeList(mappedAttendees)
  }, [])

  const table = useReactTable({
    data: attendeeList,
    columns, // Use the filtered columns
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting, //optionally control sorting state in your own scope for easy
  })

  const handleDeleteAttendee = (attendeeId: string) => {
    setSelectedAttendee(attendeeId)
    setIsShowConfirmModal(true)
  }

  const [onChangeStatus, { loading: updateStatusLoading }] = useMutation(
    CHANGE_ATTENDANT_STATUS,
    {
      onCompleted({ changeAttendeeStatus }) {
        if (changeAttendeeStatus) {
          toast({
            description: t('Change attendant status success!'),
          })
          const newStatus = String(changeAttendeeStatus.message).toLowerCase()

          setAttendee({
            ...attendee,
            status: newStatus,
          })
          const attendeeToUpdate = attendeeList.findIndex(
            person => person._id === attendee._id
          )
          const newList = [...attendeeList]
          newList[attendeeToUpdate].status = newStatus
          setAttendeeList(newList)
        }
      },
      refetchQueries: [
        {
          query: ADMIN_GET_EVENT_BY_ID,
          variables: {
            id: event?._id,
          },
        },
      ],
      onError: err => {
        err.graphQLErrors.map(({ message }) => {
          toast({
            title: t('Error'),
            description: t(message),
            variant: 'destructive',
          })
        })
      },
    }
  )

  const [mutateRemoveAttendee] = useMutation(REMOVE_ATTENDEE, {
    onCompleted({ deleteAttendee }) {
      if (deleteAttendee) {
        toast({
          description: t('Remove attendee success!'),
        })
        setIsShowConfirmModal(false)
        const newList = [...attendeeList].filter(
          person => person._id !== selectedAttendee
        )
        setAttendeeList(newList)
      }
    },
    onError: err => {
      err.graphQLErrors.map(({ message }) => {
        toast({
          title: t('Error'),
          description: t(message),
          variant: 'destructive',
        })
      })
    },
  })

  const onDeleteAttendee = () => {
    mutateRemoveAttendee({
      variables: {
        attendeeUID: selectedAttendee,
        eventUID: event._id,
      },
    })
    setIsShowConfirmModal(false)
  }

  const handleUpdateStatus = async (
    attendee: TAttendee,
    status: string,
    onClose: () => void
  ) => {
    setAttendee(attendee)
    await onChangeStatus({
      variables: {
        eventUID: event._id,
        attendeeUID: attendee._id,
        status: status,
      },
    })
    onClose()
  }

  const onActionRefundCancelBooking = (
    action: ActionRefundCancelBookingStatus,
    attendeeEmail: string,
    cancellation: Cancellation,
    registrationCodes: string[]
  ) => {
    setActionRefundWaitingInfo({
      action,
      attendeeEmail,
      cancellation,
      registrationCodes,
    })
    setIsShowActionRefundCancelModal(true)
  }

  const onFilterStatus = (status: string) => {
    table.getColumn('status')?.setFilterValue(status)
  }

  const exportEventAttendee = useExportEventAttendee(event?.title)

  const exportAttendees = async (
    eventId: string,
    attendeeStatus?: string,
    fields?: string[]
  ) => {
    setExportLoading(true)
    await exportEventAttendee({
      variables: {
        status: attendeeStatus,
        eventId,
        fields,
      },
    })
    setExportLoading(false)
  }

  const handleExportAttendee = selectedColumns => {
    const fields = Object.keys(selectedColumns).filter(
      key => selectedColumns[key]
    )

    exportAttendees(event?._id, exportStatus, fields)
  }

  const handleUpdateAttendee = async values => {
    setUpdateDetailLoading(true)
    setAttendee({
      ...attendee,
      additionalFields: values.additionalFields,
    })
    const res = await updateAttendeeDetail(event._id, attendee._id, values)

    if (res) {
      toast({
        description: t('attendee.update_attendee_success'),
      })
      const attendeeToUpdate = attendeeList.findIndex(
        person => person._id === attendee._id
      )
      const newList = [...attendeeList] as any
      newList[attendeeToUpdate].hotel = values.additionalFields?.hotel || ''
    } else {
      toast({
        description: t('attendee.update_attendee_err'),
        variant: 'destructive',
      })
    }
    setUpdateDetailLoading(false)
    setOpenModalDetail(false)
  }

  return (
    <div className='mx-auto flex justify-center overflow-y-auto p-[40px]'>
      <div className='w-full'>
        <Card className='mb-6'>
          <CardHeader>
            <CardTitle>{t('Overview')}</CardTitle>
          </CardHeader>
          <CardContent className='grid gap-6'>
            <div defaultValue='card' className='grid grid-cols-3 gap-4'>
              <div onClick={() => onFilterStatus('')}>
                <div className='cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary'>
                  <div className='text-2xl font-bold'>
                    {event.attendeesCount}
                  </div>
                  {t('Participants')}
                </div>
              </div>
              <div onClick={() => onFilterStatus(STATUS.registered)}>
                <div className='cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary'>
                  <div className='text-2xl font-bold'>
                    {numberOfRegisteredAttendees}
                  </div>
                  {t('Numbers of registered attendees')}
                </div>
              </div>
              <div onClick={() => onFilterStatus(STATUS.checked_in)}>
                <div className='cursor-pointer flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=checked]:border-primary [&:has([data-state=checked])]:border-primary'>
                  <div className='text-2xl font-bold'>
                    {numberOfCheckedInAttendees}
                  </div>
                  {t('Numbers of attendees')}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <div className='flex justify-between items-center'>
          <BasicTableSearchInput
            value={globalFilter ?? ''}
            onChange={value => setGlobalFilter(String(value))}
            className='p-2 font-lg shadow border border-block w-96'
            placeholder={t('Search attendee...')}
          />
          <div className='flex gap-2'>
            <ColumnVisibilityMenu />
            <Permission scope={USER_SCOPE.EVENT_EXPORT}>
              <Menubar>
                <MenubarMenu>
                  <MenubarTrigger className='cursor-pointer'>
                    <Download className='mr-2 h-4 w-4' />
                    {t('Export CSV')}
                  </MenubarTrigger>
                  <MenubarContent>
                    {listStatusExport.map(item => {
                      return (
                        <MenubarItem
                          key={item.status}
                          onClick={() => {
                            setOpenModalExport(true)
                            setExportStatus(item.status)
                          }}
                        >
                          {item.label}
                        </MenubarItem>
                      )
                    })}
                    <MenubarSeparator />
                    <MenubarItem
                      onClick={() => {
                        setOpenModalExport(true)
                      }}
                    >
                      {t('All')}
                    </MenubarItem>
                  </MenubarContent>
                </MenubarMenu>
              </Menubar>
            </Permission>
          </div>
        </div>

        {/* <DataTableToolbar table={table} /> */}

        {event.attendeesCount !== 0 && (
          <div className='rounded-md border overflow-x-auto mt-6'>
            <Table className=''>
              <TableHeader>
                {table.getHeaderGroups().map(headerGroup => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map(header => {
                      const canDisplayFilter =
                        header.column.getCanFilter() &&
                        // @ts-ignore
                        header.column.columnDef.Filter
                      return (
                        <TableHead
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{
                            width: header.getSize(),
                          }}
                        >
                          <div
                            // variant='ghost'
                            className={
                              canDisplayFilter
                                ? 'flex items-center space-x-2 cursor-pointer'
                                : ''
                            }
                          >
                            {header.isPlaceholder ? null : (
                              <Button
                                variant='ghost'
                                size='sm'
                                className='-ml-3 h-8 data-[state=open]:bg-accent'
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                {header.column.getCanSort() && (
                                  <Button
                                    variant='ghost'
                                    size='sm'
                                    className='ml-2 h-8 w-8 p-0'
                                    onClick={header.column.getToggleSortingHandler()}
                                  >
                                    <span className='sr-only'>Sort</span>
                                    {header.column.getIsSorted() === 'asc' ? (
                                      <ArrowUpIcon className='h-4 w-4' />
                                    ) : header.column.getIsSorted() ===
                                      'desc' ? (
                                      <ArrowDownIcon className='h-4 w-4' />
                                    ) : (
                                      <ChevronsUpDownIcon className='h-4 w-4' />
                                    )}
                                  </Button>
                                )}
                              </Button>
                            )}
                            {canDisplayFilter ? (
                              <span>
                                {/* @ts-ignore */}
                                <header.column.columnDef.Filter
                                  column={header.column}
                                />
                              </span>
                            ) : null}
                          </div>
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows.map(row => {
                  return (
                    <TableRow key={row.id}>
                      {row.getVisibleCells().map(cell => {
                        return (
                          <TableCell
                            key={cell.id}
                            className='sticky right-0 bg-white'
                            // className='border border-solid #ddd p-4'
                            style={{
                              width: cell.column.getSize(),
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </div>
        )}

        {/* pagination */}
        <Paginator
          currentPage={table.getState().pagination.pageIndex + 1}
          totalPages={table.getPageCount()}
          onPageChange={pageNumber => table.setPageIndex(pageNumber - 1)}
          showPreviousNext
          pageSize={table.getState().pagination.pageSize}
          setPageSize={value => table.setPageSize(value)}
        />
      </div>

      <Modal
        isShow={isShowConfirmModal}
        title={t('Warning')}
        description={t('Are you sure want to delete this attendee?')}
        onClose={() => setIsShowConfirmModal(false)}
        onConfirm={onDeleteAttendee}
      />
      <ActionRefundCancelBookingModal
        actionRefundWaitingInfo={actionRefundWaitingInfo}
        isShow={isShowActionRefundCancelModal}
        setIsShow={setIsShowActionRefundCancelModal}
        _id={event._id}
        tz={tz}
        currency={event.currency}
      />
      <ExportAttendeeModal
        open={openModalExport}
        onClose={() => {
          setOpenModalExport(false)
          setExportStatus(undefined)
        }}
        onExport={handleExportAttendee}
        loading={exportLoading}
        customSelections={event?.customSelections || []}
      />
      {openModalDetail && (
        <AttendeeSheet
          eventCustomSelections={eventCustomQuestions}
          attendee={attendee}
          open={openModalDetail}
          onClose={() => {
            setOpenModalDetail(false)
          }}
          handleUpdateAttendee={handleUpdateAttendee}
          loading={updateDetailLoading}
        />
      )}
    </div>
  )
}

export default Attendees
