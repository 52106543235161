import { GET_PROFESSIONALS } from 'graphql/queries'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, FormSpy, useQuery } from 'utils/adapters'
import { getListCountriesByLanguage, trimWhiteSpace } from 'utils/helpers'

import SelectAdapter from 'components/atoms/Select'
import Loading from 'components/atoms/Loading'
import { Button } from '@/components/ui/button'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { Input } from '@/components/ui/input'

type ModalEditAttendeeProps = {
  isShow: boolean
  hideModal: () => void
  attendeeInfo: any
  salutationList: { key: string; value: string }[]
  onSubmit: (values: any) => void
}

const ModalEditAttendee: FC<ModalEditAttendeeProps> = ({
  isShow,
  hideModal,
  attendeeInfo,
  salutationList,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const appLanguage = localStorage.getItem('i18nextLng')

  const { data: professionalData, loading: professionalLoading } = useQuery(
    GET_PROFESSIONALS,
    {
      onError: ({ message }) => {
        console.log(message)
      },
    }
  )

  const handleSubmit = (values: any) => {
    onSubmit({
      ...values,
      country:
        typeof values?.country === 'object'
          ? values?.country?.label
          : values?.country,
    })
    hideModal()
  }

  const validSalutations =
    salutationList?.filter(salutation => salutation.key.trim() !== '') || []

  if (professionalLoading) {
    return <Loading />
  }

  const required = (value: any) =>
    value ? undefined : t('This field is required!')

  return (
    <Dialog open={isShow} onOpenChange={hideModal}>
      <DialogContent className='max-w-3xl max-h-[80vh] overflow-y-auto'>
        <DialogHeader>
          <DialogTitle>{t('import_attendees.edit_attendee')}</DialogTitle>
        </DialogHeader>
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            ...attendeeInfo,
            salutation: attendeeInfo?.salutation || undefined,
            // country: attendeeInfo?.country || undefined,
            address: {
              street: attendeeInfo?.['address.street'] || undefined,
              zipCode: attendeeInfo?.['address.zipCode'] || undefined,
              city: attendeeInfo?.['address.city'] || undefined,
              country: attendeeInfo?.['address.country'] || undefined,
            },
          }}
          render={({ handleSubmit }) => (
            <form className='space-y-6' onSubmit={handleSubmit}>
              <FormSpy
                subscription={{ values: true, valid: true }}
                onChange={(state: any) => {
                  const { values } = state
                  sessionStorage.setItem(
                    'attendee_info',
                    JSON.stringify(values)
                  )
                }}
              />

              <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
                <div className='space-y-4'>
                  <div className='space-y-2'>
                    <Label>{t('Salutation')}</Label>
                    <Field name='salutation'>
                      {({ input }) => (
                        <Select
                          onValueChange={input.onChange}
                          defaultValue={input.value}
                        >
                          <SelectTrigger className='w-full'>
                            <SelectValue placeholder={t('Select salutation')} />
                          </SelectTrigger>
                          <SelectContent>
                            {validSalutations.map((salutation, index) => (
                              <SelectItem
                                key={salutation.key || `salutation-${index}`}
                                value={salutation.key || `salutation-${index}`}
                              >
                                {salutation.value || '-'}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    </Field>
                  </div>
                  <div className='space-y-2'>
                    <Label>{t('Academic Title')}</Label>
                    <Field name='professional'>
                      {({ input }) => (
                        <Select
                          onValueChange={input.onChange}
                          defaultValue={input.value}
                        >
                          <SelectTrigger className='w-full'>
                            <SelectValue
                              placeholder={t('Select Academic Title')}
                            />
                          </SelectTrigger>
                          <SelectContent>
                            {professionalData?.fetchProfessionals?.data?.map(
                              (
                                item: { key: string; value: string },
                                index: number
                              ) => (
                                <SelectItem
                                  key={index || item.key}
                                  value={item.key || index.toString()}
                                >
                                  {t(item.value) || '-'}
                                </SelectItem>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      )}
                    </Field>
                  </div>

                  <div className='grid grid-cols-2 gap-4'>
                    <Field
                      name='firstName'
                      type='text'
                      format={trimWhiteSpace}
                      formatOnBlur
                    >
                      {({ input, meta }: any) => (
                        <FormItem>
                          <FormLabel meta={meta}>{t('First name')}</FormLabel>
                          <Input {...input} maxLength={255} />
                        </FormItem>
                      )}
                    </Field>

                    <Field
                      name='lastName'
                      type='text'
                      format={trimWhiteSpace}
                      formatOnBlur
                    >
                      {({ input, meta }: any) => (
                        <FormItem>
                          <FormLabel meta={meta}>{t('Last name')}</FormLabel>
                          <Input {...input} maxLength={255} />
                        </FormItem>
                      )}
                    </Field>
                  </div>

                  <Field
                    name='jobTitle'
                    type='text'
                    format={trimWhiteSpace}
                    formatOnBlur
                  >
                    {({ input, meta }: any) => (
                      <FormItem>
                        <FormLabel meta={meta}>{t('Job title')}</FormLabel>
                        <Input {...input} maxLength='255' />
                      </FormItem>
                    )}
                  </Field>

                  <Field
                    name='company'
                    type='text'
                    format={trimWhiteSpace}
                    formatOnBlur
                  >
                    {({ input, meta }: any) => (
                      <FormItem>
                        <FormLabel meta={meta}>{t('Company')}</FormLabel>
                        <Input {...input} maxLength='255' />
                      </FormItem>
                    )}
                  </Field>

                  <Field
                    name='address.street'
                    type='text'
                    format={trimWhiteSpace}
                    formatOnBlur
                  >
                    {({ input, meta }: any) => (
                      <FormItem>
                        <FormLabel meta={meta}>{t('Street')}</FormLabel>
                        <Input {...input} maxLength='150' />
                      </FormItem>
                    )}
                  </Field>

                  <div className='grid grid-cols-2 gap-4'>
                    <Field name='address.zipCode' type='text'>
                      {({ input, meta }: any) => (
                        <FormItem>
                          <FormLabel meta={meta}>{t('Zip')}</FormLabel>
                          <Input {...input} maxLength='255' />
                        </FormItem>
                      )}
                    </Field>

                    <Field
                      name='address.city'
                      type='text'
                      format={trimWhiteSpace}
                      formatOnBlur
                    >
                      {({ input, meta }: any) => (
                        <FormItem>
                          <FormLabel meta={meta}>{t('City')}</FormLabel>
                          <Input {...input} maxLength='255' />
                        </FormItem>
                      )}
                    </Field>
                  </div>
                  <div className='space-y-2'>
                    <Label>{t('Country')}</Label>
                    <Field name='address.country'>
                      {({ input }) => (
                        <Select
                          onValueChange={input.onChange}
                          defaultValue={input.value}
                        >
                          <SelectTrigger className='w-full'>
                            <SelectValue placeholder={t('Select country')} />
                          </SelectTrigger>
                          <SelectContent>
                            {getListCountriesByLanguage(appLanguage).map(
                              (country, index) => (
                                <SelectItem
                                  key={country.name || `country-${index}`}
                                  value={country.name || `country-${index}`}
                                >
                                  {country.name || '-'}
                                </SelectItem>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      )}
                    </Field>
                  </div>
                </div>

                <div className='space-y-4'>
                  <Field
                    name='email'
                    type='text'
                    format={trimWhiteSpace}
                    formatOnBlur
                    validate={required}
                  >
                    {({ input, meta }: any) => (
                      <FormItem>
                        <FormLabel meta={meta} isRequired>
                          {t('Email')}
                        </FormLabel>
                        <Input {...input} maxLength='255' isRequired />
                      </FormItem>
                    )}
                  </Field>
                  <Field
                    name='workPhone'
                    type='text'
                    format={trimWhiteSpace}
                    formatOnBlur
                  >
                    {({ input, meta }: any) => (
                      <FormItem>
                        <FormLabel meta={meta}>{t('Work phone')}</FormLabel>
                        <Input {...input} maxLength='20' />
                      </FormItem>
                    )}
                  </Field>
                  <Field
                    name='mobilePhone'
                    type='text'
                    format={trimWhiteSpace}
                    formatOnBlur
                  >
                    {({ input, meta }: any) => (
                      <FormItem>
                        <FormLabel meta={meta}>{t('Mobile phone')}</FormLabel>
                        <Input {...input} maxLength='255' />
                      </FormItem>
                    )}
                  </Field>
                  <Field
                    name='fax'
                    type='text'
                    format={trimWhiteSpace}
                    formatOnBlur
                  >
                    {({ input, meta }: any) => (
                      <FormItem>
                        <FormLabel meta={meta}>{t('Fax')}</FormLabel>
                        <Input {...input} maxLength='255' />
                      </FormItem>
                    )}
                  </Field>
                  <Button
                    type='submit'
                    className='w-full text-white py-2 px-4 rounded-md transition duration-300'
                  >
                    {t('Save')}
                  </Button>
                </div>
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
}

export default ModalEditAttendee
