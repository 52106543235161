import { CustomFieldType, ICustomSelection } from '@/models'
import { FormItem, FormLabel } from '@/components/ui/final-form'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import {
  composeValidators,
  defaultDateFormat,
  maxLengthValidation,
  requiredChooseAtLeastOneValidation,
  requiredValidation,
  trimWhiteSpace,
} from 'utils/helpers'

import { Checkbox } from '@/components/ui/checkbox'
import { FC } from 'react'
import { Field } from 'utils/adapters'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { NewStyledDatePicker } from '@/components/atoms/NewDatePicker/new-datepicker'
import { SelectAdapter } from '@/components/ui/select-adapter'
import ValidationText from '@/components/atoms/ValidationText/ValidationText'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

interface IProps {
  item: ICustomSelection
  eventPrimaryColor?: string
}

export const CustomSelection: FC<IProps> = (props: IProps) => {
  const { item } = props
  const appLanguage = localStorage.getItem('i18nextLng')

  const { t } = useTranslation()

  const requiredTextField = composeValidators(
    requiredValidation,
    maxLengthValidation
  )

  const renderTextSelection = () => {
    return (
      <Field
        name={`customSelection.${item.id}`}
        type='text'
        format={trimWhiteSpace}
        validate={item.required ? requiredTextField : undefined}
        formatOnBlur
      >
        {({ input, meta }: any) => (
          <FormItem>
            <FormLabel meta={meta} {...input} isRequired={item.required}>
              {t(item.label)}
            </FormLabel>
            <Input
              {...input}
              isRequired={item.required}
              type='text'
              meta={meta}
              maxLength='255'
            />
          </FormItem>
        )}
      </Field>
    )
  }

  const renderSelectBoxSelection = () => {
    return (
      <Field
        name={`customSelection.${item.id}`}
        validate={item.required ? requiredTextField : undefined}
        component={SelectAdapter}
        placeholder={t(item.label)}
        options={[
          ...item.options.map(option => ({
            value: option.value,
            label: option.value,
          })),
        ]}
        isRequired={item.required}
        title={t(item.label)}
      />
    )
  }

  const renderDateTimeSelection = () => {
    return (
      <Field
        name={`customSelection.${item.id}`}
        formatOnBlur
        validate={item.required ? requiredTextField : undefined}
      >
        {({ input, meta }: any) => {
          return (
            <FormItem>
              <FormLabel meta={meta} {...input} isRequired={item.required}>
                {t(item.label)}
              </FormLabel>
              <NewStyledDatePicker
                locale={appLanguage as string}
                dateFormat={defaultDateFormat}
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                placeholderText={t('Select a date')}
                onChange={(date: Date) => {
                  input.onChange(moment(date).toDate())
                }}
                selected={input?.value ? new Date(input.value) : null}
              />
              {meta.error && meta.touched && (
                <ValidationText error={meta.error} />
              )}
            </FormItem>
          )
        }}
      </Field>
    )
  }

  const renderRadioSelection = () => {
    return (
      <Field
        name={`customSelection.${item.id}`}
        type='text'
        format={trimWhiteSpace}
        validate={item.required ? requiredTextField : undefined}
        formatOnBlur
      >
        {({ input, meta }: any) => (
          <FormItem>
            <FormLabel meta={meta} {...input} isRequired={item.required}>
              {t(item.label)}
            </FormLabel>
            <RadioGroup
              onValueChange={value => {
                input.onChange(value)
              }}
              value={input.value}
            >
              {item.options.map(option => (
                <div className='flex items-center space-x-2' key={option.value}>
                  <RadioGroupItem
                    value={option.value}
                    id={option.value}
                    color={props.eventPrimaryColor}
                  />
                  <Label htmlFor={option.value}>{option.value}</Label>
                </div>
              ))}
            </RadioGroup>
            {meta.error && meta.touched && (
              <ValidationText error={meta.error} />
            )}
          </FormItem>
        )}
      </Field>
    )
  }

  const renderCheckboxSelection = () => {
    return (
      <Field
        name={`customSelection.${item.id}`}
        formatOnBlur
        validate={
          item.required ? requiredChooseAtLeastOneValidation : undefined
        }
      >
        {({ input, meta }: any) => (
          <FormItem>
            <FormLabel meta={meta} {...input} isRequired={item.required}>
              {t(item.label)}
            </FormLabel>

            {item.options.map(option => {
              return (
                <div className='flex items-center space-x-2' key={option.value}>
                  <Checkbox
                    id={option.value}
                    onCheckedChange={checked => {
                      const currentValues = input?.value || []
                      return checked
                        ? input.onChange([...currentValues, option.value])
                        : input.onChange(
                            currentValues.filter(
                              value => value !== option.value
                            )
                          )
                    }}
                    checked={input.value?.includes(option.value)}
                    color={props.eventPrimaryColor}
                  />
                  <Label htmlFor={option.value}>{option.value}</Label>
                </div>
              )
            })}
            {meta.error && meta.touched && (
              <ValidationText error={meta.error} />
            )}
          </FormItem>
        )}
      </Field>
    )
  }

  const renderSelectionType = type => {
    if (type === CustomFieldType.TEXT) {
      return renderTextSelection()
    }

    if (type === CustomFieldType.SELECT_BOX) {
      return renderSelectBoxSelection()
    }

    if (type === CustomFieldType.DATE_TIME) {
      return renderDateTimeSelection()
    }

    if (type === CustomFieldType.RADIO_BUTTON) {
      return renderRadioSelection()
    }

    if (type === CustomFieldType.CHECK_BOX) {
      return renderCheckboxSelection()
    }
  }

  return (
    <>
      {item?.enabled ? (
        <FormItem className='space-y-2'>
          {renderSelectionType(item.type)}
        </FormItem>
      ) : null}
    </>
  )
}
