import { responsive } from 'assets/scss/mixin'
import { COLORS } from 'constants/colors'
import { useTranslation } from 'react-i18next'

import { FC, useState } from 'react'
import { XCircle } from 'lucide-react'

import { useDropzone } from 'react-dropzone'
import { styled } from 'utils/adapters'

import { useModal } from 'utils/hooks'

import Button from 'components/atoms/Button'
import Modal from 'components/molecules/Modal'
import 'react-resizable/css/styles.css'
import ResizableLogo from './ResizableLogo'

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return COLORS.SUCCESS
  }
  if (props.isDragReject) {
    return COLORS.DANGER
  }
  if (props.isDragActive) {
    return COLORS.BLUE
  }
  return '#eeeeee'
}

type EventUploadFileProps = {
  onDrop: ([file]: any) => void
  fileUri: string
  isLoading?: boolean
  fileState?: string
  filePreview?: any
  setPreview?: any
  bgOpacity?: number
  isResizable?: boolean
  logoSize?: string | null | undefined
  setLogoSize?: any
  setFile?: any
  errorMessage?: string
}
const EventUploadFile: FC<EventUploadFileProps> = ({
  onDrop,
  fileUri,
  isLoading,
  fileState,
  filePreview,
  children,
  bgOpacity,
  isResizable,
  logoSize,
  setLogoSize,
  setFile,
  setPreview,
  errorMessage,
}) => {
  const { t } = useTranslation()
  const { isShow, showModal, hideModal } = useModal()
  const [imgSrc, setImgSrc] = useState<string | undefined>('')
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  })

  const [size, setSize] = useState({
    width: 200,
    height: 200,
  })

  const handleOnLoadImage = event => {
    const { width, height } = event.target
    if (logoSize) {
      const [x, y] = logoSize?.split('x').map(Number)
      setSize({
        width: x,
        height: y,
      })
    } else {
      setSize({
        width: 200,
        height: Math.round(size.height * (height / width)),
      })
    }
  }

  const handleRemoveImage = () => {
    setImgSrc('')
    setFile({
      encoding: '',
      uri: '',
      filename: '',
      mimetype: '',
      fileKey: '',
    })
    setPreview(null)
  }

  return (
    <>
      <UploadContainer>
        <Container
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        >
          <input {...getInputProps()} accept='.png, .jpeg, .jpg' />
          <Title>{t('Drag and drop file here')}</Title>

          <UploadButton
            name={t('Browse file')}
            type='button'
            onClick={open}
            inputColor='fill'
            disabled={isLoading}
          />

          {filePreview
            ? filePreview.map((file: any) => (
                <Image
                  key={file.name}
                  onClick={() => {
                    setImgSrc(file.preview)
                  }}
                  bgOpacity={bgOpacity}
                  isLoading={isLoading}
                >
                  {file.preview && (
                    <>
                      <img
                        alt='logo'
                        src={file.preview}
                        onLoad={handleOnLoadImage}
                        onClick={() => showModal()}
                      />
                      <div
                        className='absolute top-[-10px] right-[-10px] w-[20px] z-[100] cursor-pointer'
                        onClick={handleRemoveImage}
                      >
                        <XCircle color='#757575' size='20px' />
                      </div>
                    </>
                  )}

                  {children}
                </Image>
              ))
            : (fileUri || fileState) && (
                <Image
                  bgOpacity={bgOpacity}
                  onClick={() => {
                    setImgSrc(fileUri ? fileUri : fileState)
                  }}
                  isLoading={isLoading}
                >
                  {fileUri && (
                    <>
                      <img
                        alt='logo'
                        src={fileUri}
                        // src={fileUri ? fileUri : fileState}
                        onLoad={handleOnLoadImage}
                        onClick={() => showModal()}
                      />
                      <div
                        className='absolute top-[-10px] right-[-10px] w-[20px] z-[100] cursor-pointer'
                        onClick={handleRemoveImage}
                      >
                        <XCircle color='#757575' size='20px' />
                      </div>
                    </>
                  )}

                  {children}
                </Image>
              )}
        </Container>
        {errorMessage && <p className='text-red-600 mt-2'>{errorMessage}</p>}
      </UploadContainer>

      {isResizable ? (
        isShow && (
          <ResizableLogo
            isShow={isShow}
            hideModal={hideModal}
            size={size}
            setSize={setSize}
            imgSrc={imgSrc}
            logoSize={logoSize}
            setLogoSize={setLogoSize}
          />
        )
      ) : (
        <Modal
          isShow={isShow}
          onClose={hideModal}
          hideHeader={true}
          hideFooter={true}
        >
          <ImageContainer>
            <img alt='logo' src={imgSrc} />
          </ImageContainer>
        </Modal>
      )}
    </>
  )
}

const UploadContainer = styled.div`
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  max-width: 400px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  transition: border 0.24s ease-in-out;
  justify-content: center;
  gap: 10px;
  align-items: center;
`

const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: #555a6b;
  text-align: center;
  ${responsive.sm`
      font-size: 20px;
  `}
`

const UploadButton = styled(Button)`
  padding: 2% 4%;
  font-size: 16px;
  max-width: 300px;

  span {
    font-size: 16px;
  }
`
const Image = styled.div<{ isLoading?: boolean; bgOpacity?: number }>`
  position: relative;
  width: 40%;
  height: 40%;
  border: 1px dashed ${COLORS.BLUE};
  padding: 5px;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    opacity: ${props =>
      props.isLoading
        ? 0.5
        : props.bgOpacity !== undefined
        ? props.bgOpacity / 100
        : 1};
    transition: all 0.25s ease;

    &:hover {
      opacity: 0.5;
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  img {
    width: inherit;
  }
`
export default EventUploadFile
